/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getSingleQrcode, newQrcode, updateQrcode } from "../../api/routes";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import queryString from "../../functions/queryString";
import NewQRFields from "./NewQRFields";

export default function NewQRCode() {
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const qrcodeId = searchParams.get("qrcodeId")
    const [loading, setLoading] = useState(false);
    const [submittedQR, setSubmittedQR] = useState();
    const [information, setInformation] = useState({});

    let schema = yup.object().shape({
        name: yup.string().required(),
        total: yup.number().required(),
        is_active: yup.string().required(),
        points_amount: yup.number(),
        wallet_amount: yup.number(),
        order: yup.number(),
        position: yup.string(),
        nfc_id: yup.string(),
    });
    useEffect(() => {
        if (method === "update" && qrcodeId) {
            setLoading(true);
            getSingleQrcode(qrcodeId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {
                        city_id,
                        name,
                        description,
                        area_id,
                        total,
                        points_amount,
                        wallet_amount,
                        order,
                        long,
                        lat,
                        remaining,
                        nfc_id,
                        is_active
                    } = data;
                    setInformation({
                        name,
                        description,
                        city_id,
                        area_id,
                        wallet_amount,
                        total,
                        order,
                        remaining,
                        nfc_id,
                        points_amount,
                        is_active: +is_active,
                        position: {
                            lat: lat,
                            lng: long
                        },
                    })

                }
            })
        }
    }, [qrcodeId]);

    const submitQrcode = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let qrData = {};
                    const { position, ...restInformation } = information;
                    for (let item in restInformation) {
                        if (restInformation[item]) {
                            qrData[item] = restInformation[item];
                        }
                    }
                    setLoading(true)

                    if (position && position.lat) {
                        qrData["lat"] = position.lat;
                        qrData["long"] = position.lng;
                    }

                    newQrcode(queryString(qrData), (isOk, data) => {
                        setLoading(false)
                        if (!isOk) {
                            return toast.error(data.message);
                        } else {
                            toast.success(`QR اضافه شد!`);
                            setInformation({})
                            setSubmittedQR(data)

                        }
                    });
                }
            }).catch(err => {
                return toast.error(err.errors[0])
            })
    }
    const submitUpdateQrcode = () => {
        let qrData = {};
        const { position, ...restInformation } = information;
        for (let item in restInformation) {
            if (restInformation[item]) {
                qrData[item] = restInformation[item];
            }
        }
        setLoading(true)

        if (position && position.lat) {
            qrData["lat"] = position.lat;
            qrData["long"] = position.lng;
        }

        updateQrcode(qrcodeId, queryString(qrData), (isOk, data) => {
            setLoading(false)
            if (!isOk) {
                return toast.error(data.message);
            } else {
                toast.success(`QR آپدیت شد!`);
                setInformation({})
                setSubmittedQR(data)
            }
        });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Loading loading={loading} />

            <NewQRFields information={information} setInformation={setInformation} setSubmittedQR={setSubmittedQR}
                submitQrcode={submitQrcode} submittedQR={submittedQR} method={method}
                submitUpdateQrcode={submitUpdateQrcode} />
        </DashboardLayout>
    )
}
