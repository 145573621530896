/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import separate from "../../functions/separate";
import SoftBadge from "../../components/SoftBadge";
import { useNavigate } from "react-router-dom";

function createData(id, businessName, menuSectionName,price, productName, percent, limit,type, usedAt, expiresAt, operation) {
    return {
        id,
        businessName,
        menuSectionName,
        price,
        productName,
        percent,
        limit,
        type,
        usedAt,
        expiresAt,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("150px")}>{row.businessName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.menuSectionName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.productName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("130px")}>{separate(row.price)}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("130px")}>{row.percent}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("130px")}
                           >{separate(row.limit)}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("138px")}
                           >{row.type}</TableCell>
                <TableCell className="tablecell" align="left" title={new Date(row.usedAt).toLocaleString('fa-IR')}
                           style={fixTableCellWidth("130px")}>{new Date(row.usedAt).toLocaleString('fa-IR')}</TableCell>
                <TableCell className="tablecell" align="left" title={new Date(row.expiresAt).toLocaleString('fa-IR')}
                           style={fixTableCellWidth("150px")}>{new Date(row.expiresAt).toLocaleString('fa-IR')}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function DiscountCardTable({data}) {
    const rows = data.map(item => createData(item.id, item?.business?.name, item?.menu_section?.name,item.price, item?.product?.name, item.percent,
        item?.limit,item.type_name, item.used_at, item.expires_at
    ))
    const navigate = useNavigate()
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained"
                                   badgeContent="طرح کارت تخفیف"
                                   onClick={()=>navigate("/discount-card-design")}
                                   style={{cursor: "pointer"}}
                                   color="success" size="xs"/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام کسب و کار</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>منو</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>محصول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>قیمت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>درصد</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>محدودیت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>نوع کارت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>تاریخ استفاده</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>تاریخ ایحاد</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
