/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import GenerateQR from "../../components/GenerateQR";
import LeafletMap from "../../components/LeafletMap";
import SoftBadge from "../../components/SoftBadge";

export default function NewQRFields({
                                        information,
                                        setInformation,
                                        submittedQR,
                                        setSubmittedQR,
                                        submitQrcode,
                                        method,
                                        submitUpdateQrcode
                                    }) {
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };

    return (
        <>
            {submittedQR && <SoftBadge variant="contained"
                                       badgeContent="بازگشت"
                                       style={{cursor: "pointer"}}
                                       onClick={() => setSubmittedQR(undefined)}
                                       size="lg"/>}
            {!submittedQR ? <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تعداد کل
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="total" value={information.total} onChange={handleChange}
                                   type="text" placeholder="تعداد کل"/>
                    </Grid>
                    {method === "update" && <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تعداد باقی مانده
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="remaining" value={information.remaining} onChange={handleChange}
                                   type="text" placeholder="تعداد باقی مانده"/>
                    </Grid>}
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مقدار امتیاز
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="points_amount" value={information.points_amount} onChange={handleChange}
                                   type="text" placeholder="مقدار امتیاز"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مقدار کیف پول
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="wallet_amount" value={information.wallet_amount} onChange={handleChange}
                                   type="text" placeholder="مقدار کیف پول"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ترتیب
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="order" value={information.order} onChange={handleChange}
                                   type="text" placeholder="ترتیب"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                NFC id
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="nfc_id" value={information.nfc_id} onChange={handleChange}
                                   type="text" placeholder="NFC id"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                وضعیت نمایش
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_active" value={String(information.is_active)} onChange={handleChange}
                                fullWidth
                                placeholder='Is active'>
                            <MenuItem value={"1"}>فعال</MenuItem>
                            <MenuItem value={"0"}>غیرفعال</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={12}>
                        <LeafletMap style={{width: "100%", height: 300}} selectedPosition={information.position}
                                    setSelectedPosition={(location) => setInformation((prev) => ({
                                        ...prev,
                                        position: location
                                    }))}
                                    zoom={12}/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        {method !== "update" ?
                            <SoftButton onClick={submitQrcode} style={{marginTop: 40}} variant="gradient" color="info">
                                ثبت
                            </SoftButton> :
                            <SoftButton onClick={submitUpdateQrcode} style={{marginTop: 40}} variant="gradient"
                                        color="info">
                                آپدیت
                            </SoftButton>}
                    </Grid>
                </Grid>
            </SoftBox> : <GenerateQR QRInfo={submittedQR}/>}
        </>
    )
}
