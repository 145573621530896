import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import queryString from "../../functions/queryString";
import {getBusiness} from "../../api/routes";
import {useSearchParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import BusinessTable from "./businessTable";

export default function Business() {
    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [business, setBusiness] = useState([]);
    const [searchInfo, setSearchInfo] = useState({});
    const [trigger, setTrigger] = useState(null);
    const handleChange = (event) => {
        const {name, value} = event.target;
        // clear cursor and set page to zero when search
        setPage(0);
        setCursor('');
        setSearchInfo({[name]: value});
    };

    useEffect(() => {
        // debounce for 1 second
        const getData = setTimeout(() => {
            setLoading(true);
            const query = {
                cursor: cursor === null ? null : cursor,
                name: searchInfo?.name ? searchInfo.name : null,
                status: searchInfo?.status === "all" ? null : searchInfo?.status || null
            }
            getBusiness(queryString(query), trigger !== null, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    if (page !== 0) {
                        setBusiness((prev) => ([...prev, ...data.data]));
                    } else {
                        setBusiness(data.data);
                    }
                    setLoading(false);
                    setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                    if (data.meta.next_cursor !== null)
                        return setHasMore(true);

                    else return setHasMore(false)
                }
            })
        }, (searchInfo.name) ? 1000 : 0);

        return () => {
            clearTimeout(getData)
        }

    }, [page, searchInfo,trigger]);

    useEffect(() => {
        if (searchParams.get("update")) {
            if (page === 0)
                setTrigger(Math.random());
            else
                setPage(0);

            setCursor(null);
        }
    }, [searchParams.get("update")]);

    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <InfiniteScroll dataLength={business.length} next={fetchMoreData} hasMore={hasMore}>
                <BusinessTable data={business} searchInfo={searchInfo} handleFilterChange={handleChange}/>
            </InfiniteScroll>
        </DashboardLayout>
    )
}
