/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import ellipsis from "../../functions/ellipsis";
import SoftBadge from "../../components/SoftBadge";
import {useNavigate} from "react-router-dom";
import {deleteFeature} from "../../api/routes";
import {toast} from "react-toastify";
import {useState} from "react";
import Loading from "../../components/Loading";

function createData(id, name, description, isMulti, order, items, operation) {
    return {
        id,
        name,
        description,
        isMulti,
        order,
        items,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}, maxHeight: "50px"}}>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.description}
                           style={fixTableCellWidth("230px")}>{ellipsis(row.description, 40)}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.isMulti ? "بله" : "خیر"}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("130px")}>{row.order}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("130px")}>{row.items.join(",")}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function FeatureTable({data}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const handleDeleteFeature = (featureId) => {
        let answer = window.confirm("آیا از حذف اطمینان دارید؟");
        if (answer) {
            setLoading(true);
            deleteFeature(featureId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                    window.location.reload()
                }
            })
        }
    }

    const rows = data.map(item => createData(item.id, item.name, item.description, item.is_multi, item.order, item.items,
        <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained" onClick={() => navigate(`/new-feature?method=update&featureId=${item.id}`)}
                       badgeContent="ویرایش"
                       color="secondary" size="xs"/>
            <SoftBadge variant="contained" onClick={() => handleDeleteFeature(item.id)}
                       badgeContent="حذف"
                       color="error" size="xs"/>
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Loading loading={loading}/>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained" onClick={() => navigate("/new-feature")}
                                   badgeContent="ویژگی جدید" color="success" size="xs"
                                   container
                                   style={{cursor: 'pointer'}}/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام ویژگی</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("210px")}>توضیحات</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>انتخاب چند
                                آیتم؟</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>ترتیب</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>آیتم ها</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>عملیات</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
