/* eslint-disable react/prop-types */
import * as React from "react";
import ModalUi from "../../components/ModalUI";
import {Alert} from "@mui/material";

export default function MoreInfoModal({modalInfo, closeModal}) {
    return(
        <ModalUi open={modalInfo.state} handleClose={closeModal} style={{minWidth: "600px"}}>
            <Alert severity="info" variant={"outlined"} sx={{mb:1}}>ضریب پیشرفت: {modalInfo.info.pvalue ?? 0}</Alert>
            <Alert severity="info" sx={{mb:1}}>تاریخ شروع: {new Date(modalInfo.info.start_at).toLocaleString('fa-IR')}</Alert>
            <Alert severity="info" variant={"outlined"} sx={{mb:1}}>تاریخ پایان: {new Date(modalInfo.info.end_at).toLocaleString('fa-IR')}</Alert>
            <Alert severity="info" sx={{mb:1}}>تاریخ ایجاد: {new Date(modalInfo.info.created_at).toLocaleString('fa-IR')}</Alert>
        </ModalUi>
    )
}
