/* eslint-disable react/prop-types */
import ModalUi from "../../components/ModalUI";
import NewMugFields from "./NewMugFields";
import {useEffect, useState} from "react";
import * as yup from "yup";
import {getSingleMug, newMug, updateMug} from "../../api/routes";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";
import queryString from "../../functions/queryString";

export default function NewMugModal({modalInfo, closeModal}) {
    const [information, setInformation] = useState({});
    const [loading, setLoading] = useState(false);
    let schema = yup.object().shape({
        count: yup.number().required(),
        sponsor: yup.string().nullable(),
        additional_points: yup.number().nullable()
    });

    useEffect(() => {
        if (modalInfo.mugId) {
            setLoading(true);
            getSingleMug(modalInfo.mugId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {
                        sponsor,
                        additional_points
                    } = data;
                    setInformation({
                        sponsor,
                        additional_points
                    })

                }
            })
        }
    }, [modalInfo.mugId]);

    const submitNewMug = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();
                    for (let item in information) {
                        if (information[item]) {
                            formdata.append(item, information[item]);
                        }
                    }

                    setLoading(true);
                    newMug(formdata, (isOk, data) => {
                        setLoading(false);
                        if (!isOk) {
                            return toast.error(data.message);
                        }

                        toast.success("ماگ جدید اضافه شد!");
                        setInformation({})
                        closeModal()
                    });
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    const submitUpdateMug = () => {
        let data = {
            sponsor: information.sponsor,
            additional_points: information.additional_points
        }
        if (modalInfo.mugId) {
            setLoading(true);
            updateMug(modalInfo.mugId, queryString(data), (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("ماگ ویرایش شد!");
                    closeModal()
                }
            })
        }
    }
    return (
        <ModalUi open={modalInfo.state} handleClose={closeModal} style={{minWidth: "600px"}}>
            <Loading loading={loading}/>
            <NewMugFields information={information} setInformation={setInformation} submit={submitNewMug}
                          handleUpdate={submitUpdateMug} method={modalInfo.mugId ? "update" : "new"}/>
        </ModalUi>
    )
}
