/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import useModal from "../../hooks/useModal";
import SoftBadge from "../../components/SoftBadge";
import Loading from "../../components/Loading";
import SubCategoryModal from "../business/modal/SubCategoryModal";
import handleDeleteProductCategory from "./handleDeleteProductCategory";
import ellipsis from "../../functions/ellipsis";

function createData(id, name, description, operation) {
    return {
        id,
        name,
        description,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("200px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.description}
                           style={fixTableCellWidth("320px")}>{ellipsis(row.description,70)}</TableCell>
                <TableCell className="tablecell" align="left" style={{
                    ...fixTableCellWidth("300px"),
                    cursor: "pointer"
                }}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ProductCategoryTable({data}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [modals, updateModal, closeModal] = useModal({
        subCategory: {},
    })
    const closeSubCategory = () => closeModal('subCategory');

    const rows = data.map(item => createData(item.id, item.name, item.description,
        <React.Fragment>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("subCategory", {state: true, data: item.sub_categories})}
                       badgeContent="زیر دسته بندی" color="info" size="xs" container style={{marginLeft: 5}}/>
            <SoftBadge variant="contained" badgeContent="آپدیت" color="secondary" size="xs" style={{marginLeft: 5}}
                       container
                       onClick={() => navigate(`/new-product-category?method=update&categoryId=${item.id}`)}/>
            <SoftBadge variant="contained" onClick={()=>handleDeleteProductCategory(item.id,setLoading)} badgeContent="حذف" color="error" size="xs" container/>
        </React.Fragment>
    ))

    return (
        <>
            <Loading loading={loading}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained" onClick={() => navigate("/new-product-category")}
                                   style={{cursor: "pointer"}} badgeContent="دسته بندی جدید" color="success" size="xs"
                                   container/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("200px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("300px")}>توضیحات</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("300px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.subCategory.state &&
                <SubCategoryModal updatePath={"new-product-category"} subCategory={modals.subCategory}
                                  handleDeleteCategory={handleDeleteProductCategory}
                                  closeSubCategory={closeSubCategory} setLoading={setLoading}/>}
        </>
    );
}
