

import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./style/global.css";
import { SoftUIControllerProvider } from "context";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
        <App/>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
