import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getSingleJourney, newJourney, updateJourney} from "../../api/routes";
import randomToken from "../../functions/randomToken";
import NewJourneyFields from "./NewJourneyFields";
import SoftButton from "../../components/SoftButton";
import QrModal from "./qrModal";
import useModal from "../../hooks/useModal";
import SoftTypography from "../../components/SoftTypography";
import imageCompression from "browser-image-compression";
import findChangedData from "../../functions/findDataChanges";

export default function NewJourney() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const journeyId = searchParams.get("journeyId")
    const modal = searchParams.get("modal")
    const [initInformation, setInitInformation] = useState({});
    const [modals, updateModal, closeModal] = useModal({
        qrcode: {state: false},
    })
    const closeQRCode = () => closeModal('qrcode');
    let schema = yup.object().shape({
        city_id: yup.string().required(),
        name: yup.string().required(),
        description: yup.string().required(),
        area_id: yup.string(),
        is_order_based: yup.string().required(),
        need_complete: yup.string().required(),
        pvalue: yup.string(),
        ppercent: yup.string(),
        start_at: yup.string().required(),
        end_at: yup.string().required(),
        is_active: yup.string(),
        image: yup.mixed().required(),
        category: yup.string(),
        poster: yup.mixed(),
    });

    useEffect(() => {
        if (method === "update" && journeyId) {
            setLoading(true);
            getSingleJourney(journeyId, "",(isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {
                        city_id,
                        name,
                        description,
                        area_id,
                        is_order_based,
                        need_complete,
                        pvalue,
                        ppercent,
                        start_at,
                        end_at,
                        is_active,
                        category,
                        poster
                    } = data;
                    setInitInformation(() => {
                        let newData = {}
                        for (let item in data)
                            if (typeof data[item] === 'boolean')
                                newData[item] = +data[item];
                            else newData[item] = data[item];

                        return newData
                    })
                    setInformation({
                        name,
                        description,
                        city_id,
                        area_id,
                        pvalue,
                        ppercent,
                        category,
                        start_at,
                        is_active: +is_active,
                        is_order_based: +is_order_based,
                        need_complete: +need_complete,
                        end_at,
                        poster,
                    })

                }
            })
        }
    }, [journeyId]);
    const submitNewJourney = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();
                    const {image, poster, ...restInformation} = information;
                    for (let item in restInformation) {
                        if (restInformation[item]) {
                            formdata.append(item, restInformation[item]);
                        }
                    }
                    setLoading(true);
                    if (image && image.length > 0 && image[0] instanceof File) {
                        const compressedImage = await imageCompression(image[0], {maxSizeMB: 2});
                        formdata.append(`image`, compressedImage);
                    }
                    if (poster && poster.length > 0 && poster[0] instanceof File) {
                        const compressedPoster = await imageCompression(poster[0], {maxSizeMB: 2});
                        formdata.append(`poster`, compressedPoster);
                    }
                    newJourney(formdata, (isOk, data) => {
                        setLoading(false);
                        if (!isOk) {
                            return toast.error(data.message);
                        } else {
                            toast.success("Your request has been submitted");
                            setInformation({})
                            navigate(`/new-journey?journeyId=${data.id}&modal=true&journeyName=${information.name}`);
                        }
                    });
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    const handleJourneyUpdate = async () => {
        let formdata = new FormData();
        const changedData = findChangedData(initInformation, information);
        const {image, poster, ...restInformation} = changedData;
        for (let item in restInformation) {
            if (information[item]) {
                formdata.append(item, information[item]);
            }
        }
        setLoading(true);
        if (image && image.length > 0 &&  image[0] instanceof File) {
            const compressedImage = await imageCompression(image[0], {maxSizeMB: 2});
            formdata.append(`image`, compressedImage);
        }
        if (poster && poster.length > 0 && poster[0] instanceof File) {
            const compressedPoster = await imageCompression(poster[0], {maxSizeMB: 2});
            formdata.append(`poster`, compressedPoster);
        }
        updateJourney(journeyId, formdata, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/journey?update=${randomToken()}`);
            }
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>

            {modal && journeyId ? <>
                    {searchParams.get("journeyName") &&
                        <SoftTypography component="label" variant="h6" fontWeight="bold">چالش با نام <span
                            style={{color: "green"}}>{searchParams.get("journeyName")}</span> اضافه شد.</SoftTypography>}
                    <br/>
                    <SoftButton style={{marginTop: 16}} onClick={() => updateModal("qrcode", {state: true})} color={"info"}>اضافه
                        کردن
                        QR</SoftButton>
                    <SoftButton style={{marginTop: 16, marginRight: 10}} onClick={() => navigate(-1)}
                                color={"error"}>بازگشت</SoftButton></> :
                <NewJourneyFields method={method} information={information}
                                  handleUpdate={handleJourneyUpdate}
                                  setInformation={setInformation} submit={submitNewJourney}/>}
            {modals.qrcode.state && <QrModal modalInfo={modals.qrcode} closeModal={closeQRCode}/>}

        </DashboardLayout>
    )
}
