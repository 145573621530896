import {useEffect, useState} from "react";
import {getBanner} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import InfiniteScroll from "react-infinite-scroll-component";
import BannerTable from "./BannerTable";
import {useSearchParams} from "react-router-dom";

export default function Banner(){
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [banner, setBanner] = useState([]);
    const [trigger, setTrigger] = useState(null);
    const [searchParams] = useSearchParams()

    useEffect(() => {
        setLoading(true);
        const query = {
            cursor: cursor === null ? null : cursor
        }
        getBanner(queryString(query), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                if (page !== 0) {
                    setBanner((prev) => ([...prev, ...data.data]));
                } else {
                    setBanner(data.data);
                }
                setLoading(false);
                setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                if (data.meta.next_cursor !== null)
                    return setHasMore(true);

                else return setHasMore(false)
            }
        }, trigger !== null)

    }, [page, trigger]);

    useEffect(() => {
        if (searchParams.get("update")) {
            if (page === 0)
                setTrigger(Math.random());
            else
                setPage(0);

            setCursor(null);
        }
    }, [searchParams.get("update")]);


    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return(
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <InfiniteScroll dataLength={banner.length} next={fetchMoreData} hasMore={hasMore}>
                <BannerTable data={banner}/>
            </InfiniteScroll>
        </DashboardLayout>
    )
}
