import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'

export default function Home() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
        </DashboardLayout>
    )
}
