import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getJourney } from "../../api/routes";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import queryString from "../../functions/queryString";
import JourneyTable from "./JourneyTable";

export default function Journey() {
    const [searchParams] = useSearchParams()
    const [trigger, setTrigger] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [journey, setJourney] = useState([]);

    useEffect(() => {
        setLoading(true);
        const query = {
            cursor: cursor === null ? null : cursor,
            with_qr_counts: 1
        }
        getJourney(queryString(query), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                if (page !== 0) {
                    setJourney((prev) => ([...prev, ...data.data]));
                } else {
                    setJourney(data.data);
                }
                setLoading(false);
                setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                if (data.meta.next_cursor !== null)
                    return setHasMore(true);

                else return setHasMore(false)
            }
        }, trigger !== null)

    }, [page, trigger]);
    useEffect(() => {
        if (searchParams.get("update")) {
            if (page === 0)
                setTrigger(Math.random());
            else
                setPage(0);

            setCursor(null);
        }
    }, [searchParams.get("update")]);
    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return (
        <DashboardLayout>
            <Loading loading={loading} />
            <DashboardNavbar />
            <InfiniteScroll dataLength={journey.length} next={fetchMoreData} hasMore={hasMore}>
                <JourneyTable data={journey} />
            </InfiniteScroll>
        </DashboardLayout>
    )
}
