/* eslint-disable react/prop-types */
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { getMugSponsors } from "../../api/routes";
import { toast } from "react-toastify";

export default function MugSponsors({ sponsor, handleChange }) {
  const [mugSponsors, setMugSponsors] = useState([]);

  useEffect(() => {
    getMugSponsors((isOk, data) => {
      if (!isOk) {
        return toast.error(data.message);
      } else {
        setMugSponsors(data);
      }
    });
  }, []);
  return (
    <FormControl style={{ marginRight: 20, marginTop: 10, marginBottom: 10 }}>
      <RadioGroup row value={sponsor} onChange={handleChange}>
        {mugSponsors.map(item => item && <FormControlLabel key={item} value={item} control={<Radio />} label={item} />)}
      </RadioGroup>
    </FormControl>
  );
}
