/* eslint-disable react/prop-types */
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import {useEffect, useState} from "react";
import {getArea, getBusinessCategory, getCity} from "../../api/routes";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import queryString from "../../functions/queryString";
import LeafletMap from "../../components/LeafletMap";
import * as React from "react";

export default function NewBusinessFields({method, information, setInformation,handleUpdate, submit}) {
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [loading, setLoading] = useState(false);
    const [businessCategory, setBusinessCategory] = useState([]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };

    useEffect(() => {
        const query = {
            country_id: 1
        }
        setLoading(true);
        getCity(queryString(query), true, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else setCity(data);

        });
        getBusinessCategory(true, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                setBusinessCategory(data);
            }
        });
    }, []);

    useEffect(() => {
        if (information?.city_id !== undefined) {
            const query = {
                city_id: information?.city_id,
            }
            setLoading(true);
            getArea(queryString(query), false, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setArea(data)
                }
            })
        }
    }, [information?.city_id]);

    const handleImageChange = (event) => {
        const {name, files} = event.target;
        setInformation({...information, [name]: files})
    };

    return (
        <SoftBox py={3}>
            <Loading loading={loading}/>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام کسب و کار
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام کسب و کار"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                آدرس
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="address" value={information.address} onChange={handleChange}
                                   type="text" placeholder="آدرس"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شماره تماس 1
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="contact_number_1" value={information.contact_number_1} onChange={handleChange}
                                   type="text" placeholder="شماره تماس 1"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شماره تماس 2
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="contact_number_2" value={information.contact_number_2} onChange={handleChange}
                                   type="text" placeholder="شماره تماس 2"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شهر
                            </SoftTypography>
                        </SoftBox>
                        <Select name="city_id" value={String(information.city_id)} onChange={handleChange}
                                fullWidth
                                placeholder='شهر'>
                            {city.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                منطقه
                            </SoftTypography>
                        </SoftBox>
                        <Select name="area_id" value={String(information.area_id)} onChange={handleChange}
                                fullWidth
                                placeholder='منطقه'>
                            {area.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                دسته بندی
                            </SoftTypography>
                        </SoftBox>
                        <Select name="category_ids" multiple value={information.category_ids ?? []}
                                onChange={handleChange}
                                fullWidth
                                placeholder='دسته بندی'>
                            {businessCategory.map(item => <MenuItem key={item.id}
                                                                    value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    {method === "update" && <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                وضعیت نمایش
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_active" value={String(information.is_active)} onChange={handleChange}
                                fullWidth
                                placeholder='Is active'>
                            <MenuItem value={"1"}>فعال</MenuItem>
                            <MenuItem value={"0"}>غیرفعال</MenuItem>
                        </Select>
                    </Grid>}
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام کاربر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_first_name" value={information.user_first_name} onChange={handleChange}
                                   type="text" placeholder="نام کاربر"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام خانوادگی کاربر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_last_name" value={information.user_last_name} onChange={handleChange}
                                   type="text" placeholder="نام خانوادگی کاربر"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام کاربری
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_username" value={information.user_username} onChange={handleChange}
                                   type="text" placeholder="نام کاربری"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شماره موبایل کاربر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_phone_number" value={information.user_phone_number}
                                   onChange={handleChange}
                                   type="text" placeholder="9890xxxxxxx"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ایمیل کاربر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_email" value={information.user_email} onChange={handleChange}
                                   type="text" placeholder="ایمیل کاربر"/>
                    </Grid>
                    {method !== "update" && <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                رمز عبور کاربر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_password" value={information.user_password} onChange={handleChange}
                                   type="text" placeholder="رمز عبور کاربر"/>
                    </Grid>}
                    {method !== "update" && <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تکرار رمز عبور کاربر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="user_password_confirmation" value={information.user_password_confirmation}
                                   onChange={handleChange}
                                   type="text" placeholder="تکرار رمز عبور کاربر"/>
                    </Grid>}
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                آواتار
                            </SoftTypography>

                        </SoftBox>
                        <input name="avatar" onChange={handleImageChange} type="file" style={{
                            background: 'white',
                            padding: '8px 12px',
                            width: '100%',
                            border: '0.0625rem solid #d2d6da',
                            borderRadius: '0.5rem',
                            color: '#495057'
                        }} placeholder="Images" accept=".jpg,.jpeg,.png"/>
                    </Grid>
                    {method !== "update" && <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مدارک
                            </SoftTypography>

                        </SoftBox>
                        <input name="documents" onChange={handleImageChange} type="file" multiple style={{
                            background: 'white',
                            padding: '8px 12px',
                            width: '100%',
                            border: '0.0625rem solid #d2d6da',
                            borderRadius: '0.5rem',
                            color: '#495057'
                        }} placeholder="Images" accept=".jpg,.jpeg,.png"/>
                    </Grid>}
                    <Grid xs={12} mt={3} sm={12} xl={12}>
                        <LeafletMap style={{width: "100%", height: 300}} selectedPosition={information.position}
                                    setSelectedPosition={(location) => setInformation((prev) => ({
                                        ...prev,
                                        position: location
                                    }))}
                                    zoom={12}/>
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        آپدیت
                    </SoftButton>}
            </SoftBox>
        </SoftBox>
    )
}
