/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../../../functions/fixTableCellWidth";
import SoftBadge from "../../../../components/SoftBadge";
import AreasModal from "../areas";
import useModal from "../../../../hooks/useModal";
import {deleteCity, newCity} from "../../../../api/routes";
import {toast} from "react-toastify";
import {useState} from "react";
import Loading from "../../../../components/Loading";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftInput from "../../../../components/SoftInput";
import SoftButton from "../../../../components/SoftButton";
import Icon from "@mui/material/Icon";

function createData(id, name, operation) {
    return {
        id,
        name,
        operation
    };
}

function Row(props) {
    const {row, stats} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={{...fixTableCellWidth("180px"), paddingRight: 30}}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{stats?.find(item => item?.model_id === row.id)?.count ?? 0}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CitiesTable({data, stats, countryId}) {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [information, setInformation] = useState({});
    const [modals, updateModal, closeModal] = useModal({
        areas: {},
    })
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    const closeAreas = () => closeModal('areas');
    const handleDeleteCity = (cityId) => {
        let answer = window.confirm("Are you sure about deleting city?");
        if (answer) {
            setLoading(true);
            deleteCity(cityId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                }
            })
        } else {
        }
    }
    const rows = data.map(item => createData(item.id, item.name, <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       badgeContent="منطقه ها"
                       onClick={() => updateModal("areas", {state: true, cityId: item.id})}
                       color="info" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => handleDeleteCity(item.id)}
                       badgeContent="حذف"
                       color="error" size="xs"/>
        </section>
    ))

    const submitNewCity = () => {
        let formdata = new FormData();

        formdata.append("name", information?.name);
        formdata.append("country_id", countryId);

        setLoading(true);
        newCity(formdata, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                setStep(0)
                setInformation({})
            }
        })
    }
    return (
        <>
            <Loading loading={loading}/>
            {step === 0 && <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained"
                                   badgeContent="شهر جدید"
                                   onClick={() => setStep(1)}
                                   style={{cursor: "pointer"}}
                                   color="success" size="xs"/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>کاربران</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} stats={stats} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            {step === 1 && <>
                <Icon onClick={() => setStep(0)}
                      style={{cursor: "pointer", position: "absolute", left: 25}}>arrow_back_ios_new</Icon>
                <SoftBox mb={1} ml={0.5} mt={2}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        شهر جدید
                    </SoftTypography>
                </SoftBox>
                <SoftInput name="name" value={information.name} onChange={handleChange}
                           type="text" placeholder="شهر جدید"/>
                <SoftButton onClick={submitNewCity} style={{marginTop: 30}} variant="gradient" color="info">
                    ثبت
                </SoftButton>
            </>}
            {modals.areas.state && <AreasModal areas={modals.areas} closeAreas={closeAreas}/>}
        </>
    );
}
