/**
 *
 * @param {object} initData
 * @param {object} newData
 * @return {object}
 */
export default function findChangedData(initData,newData){
    let changedData={}
    for(let item in newData){
        if(newData[item] !== initData[item])
            changedData[item] = newData[item];
    }
    return changedData;
}
