/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import SoftBadge from "../../components/SoftBadge";
import separate from "../../functions/separate";
import useModal from "../../hooks/useModal";
import UpdateModal from "./UpdateModal";
import TableHeadItems from "./TableHeadItems";
import DiscountCardModal from "./DiscountCardModal";

function createData(id, businessName, menuSectionName,price, productName, percent, limit,count, remaining,isReady, expiresAt, operation) {
    return {
        id,
        businessName,
        menuSectionName,
        price,
        productName,
        percent,
        limit,
        count,
        remaining,
        isReady,
        expiresAt,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("150px")}>{row.businessName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("140px")}>{row.menuSectionName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("140px")}>{row.productName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("130px")}>{row.price ? separate(row.price) :""}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row.percent}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("100px")}
                           >{separate(row.limit)}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("100px")}
                           >{row.count}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row.remaining}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row.isReady ? "Yes" : "No"}</TableCell>
                <TableCell className="tablecell" align="left" title={row.expiresAt}
                           style={fixTableCellWidth("150px")}>{row.expiresAt}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function DiscountCardIssueTable({data,searchInfo,handleChange}) {
    const [modals, updateModal, closeModal] = useModal({
        update: {},
        discountCard: {},
    })
    const closeUpdateModal = () => closeModal('update');
    const closeDiscountCardModal = () => closeModal('discountCard');

    const rows = data.map(item => createData(item.id, item?.business?.name, item?.menu_section?.name,item.card_price, item?.product?.name, item.percent,
        item?.limit,item.count, item.remaining,item.is_ready, item.expires_at, <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("discountCard", {state: true, discountCardIssueId: item.id})}
                       badgeContent="کارت تخفیف"
                       color="success" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("update", {state: true, discountCardIssueId: item.id})}
                       badgeContent="آپدیت"
                       color="info" size="xs"/>
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableHeadItems filterInfo={searchInfo} handleFilterChange={handleChange}/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام کسب و کار</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>منو</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>محصول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>قیمت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>درصد</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>محدودیت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>تعداد کل</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>تعداد باقی مانده</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>Is Ready</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>تاریخ انقضا</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.update.state && <UpdateModal updateModal={modals.update} closeUpdateModal={closeUpdateModal}/> }
            {modals.discountCard.state && <DiscountCardModal modalInfo={modals.discountCard} closeModal={closeDiscountCardModal}/> }
        </>
    );
}
