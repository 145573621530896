import * as React from "react";
import QRCode from "react-qr-code";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import SoftButton from "../../components/SoftButton";
import Loading from "../../components/Loading";
import { getSingleJourney } from "../../api/routes";
import { toast } from "react-toastify";
import queryString from "../../functions/queryString";

export default function JourneyQRCodesPreview() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (state) {
      setLoading(true);
      const query = {
        with_journey_qr: 1,
      };
      getSingleJourney(state, queryString(query), (isOk, data) => {
        setLoading(false);
        if (!isOk) {
          return toast.error(data.message);
        } else {
          setData(data?.q_r_codes);
        }
      });
    }
  }, [state]);
  const handleAfterPrint = React.useCallback(() => {
    setLoading(false);
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setLoading(true);
  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Journey-QRs",
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={loading} />
      <SoftButton style={{ marginLeft: 10 }} variant="gradient"
                  onClick={() => navigate("/journey")} circular
                  size={"small"} color="info">
        بازگشت
      </SoftButton>
      <SoftButton variant="gradient"
                  onClick={handlePrint} circular
                  size={"small"} color="warning">
        چاپ
      </SoftButton>
      <div ref={componentRef} style={{display:"flex",flexWrap:"wrap"}}>
        {data.map(item => <section key={item.id} style={{margin: 20}}>
          <QRCode
            size={256}
            value={item?.uuid}
            viewBox={`0 0 256 256`}
            style={{ padding: 4, backgroundColor: "white" }}
          />
          <p style={{fontSize:14,textAlign:"center"}}>{item.name}</p>
        </section>)}
      </div>
    </DashboardLayout>
  );
}
