/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import ModalUi from "../../components/ModalUI";
import separate from "../../functions/separate";
import ellipsis from "../../functions/ellipsis";

export default function ProductsModal({products,closeProducts}){
    return(
        <ModalUi open={products.state} handleClose={closeProducts}>
            <div style={{minWidth: 400, margin: '2rem'}}>
                {products.data.map((product, index) => <Grid key={index} container gap={2} mb={2} style={{
                    fontSize: 16,
                    borderBottom: products.data.length - 1 !== index &&"1px solid #e7e7e7",
                    paddingBottom: 10
                }}>
                    <Grid item xs={4}>
                        <p title={product.name} style={{fontWeight: "bold"}}>نام: <span
                            style={{fontWeight: "normal"}}>{product.name}</span>
                        </p>
                    </Grid>
                    <Grid item xs={4}>
                        <p title={product.description} style={{fontWeight: "bold", marginLeft: 10}}>توضیحات: <span
                            style={{fontWeight: "normal"}}>{product.description ? ellipsis(product.description, 70) : ""}</span></p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{fontWeight: "bold", marginLeft: 10}}>قیمت: <span
                            style={{fontWeight: "normal"}}>{separate(product.price)}</span></p>
                    </Grid>
                </Grid>)}
            </div>
        </ModalUi>
    )
}
