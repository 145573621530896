/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../../functions/fixTableCellWidth";

function createData(id, businessName, request) {
    return {
        id,
        businessName,
        request
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left"
                           style={{...fixTableCellWidth("170px"), paddingLeft: 25}}>{row.businessName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row?.request?.is_approved ? "بله" : "خیر"}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("170px")}>{new Date(row?.request?.updated_at).toLocaleString('fa-IR')}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("170px")}>{new Date(row?.request?.created_at).toLocaleString('fa-IR')}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function BusinessOfPersonTable({data}) {
    const rows = data.map(item => createData(item.id, item?.name, item.request))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام کسب و
                                کار</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>قبول
                                درخواست؟</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("170px")}>تاریخ آپدیت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("170px")}>تاریخ ایجاد</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
