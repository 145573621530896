import {useEffect, useState} from "react";
import {getDiscountCardIssue} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Loading from "../../components/Loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import InfiniteScroll from "react-infinite-scroll-component";
import DiscountCardIssueTable from "./DiscountCardIssueTable";
import {useSearchParams} from "react-router-dom";

export default function DiscountCardIssue() {
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [discountCard, setDiscountCard] = useState([]);
    const [trigger, setTrigger] = useState(0);
    const [searchInfo, setSearchInfo] = useState({});
    const handleChange = (event) => {
        const {name, value} = event.target;
        if (page === 0)
            setTrigger(Math.random());
        else
            setPage(0);
        setCursor('');
        setSearchInfo({...searchInfo,[name]: value});
    };

    useEffect(() => {
        setLoading(true);
        const query = {
            cursor: cursor === null ? null : cursor,
            is_ready: searchInfo?.is_ready === "all" ? null : searchInfo?.is_ready || null,
            business_id: searchInfo?.business_id === "all" ? null : searchInfo?.business_id || null,
            sort_by: "created_at",
            sort_order: "desc"
        }
        getDiscountCardIssue(queryString(query), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                if (page !== 0) {
                    setDiscountCard((prev) => ([...prev, ...data.data]));
                } else {
                    setDiscountCard(data.data);
                }
                setLoading(false);
                setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                if (data.meta.next_cursor !== null)
                    return setHasMore(true);

                else return setHasMore(false)
            }
        }, trigger !== null)
    }, [page, trigger]);
    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    useEffect(() => {
        if (searchParams.get("update")) {
            if (page === 0)
                setTrigger(Math.random());
            else
                setPage(0);

            setCursor(null);
        }
    }, [searchParams.get("update")]);
    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <InfiniteScroll dataLength={discountCard.length} next={fetchMoreData} hasMore={hasMore}>
                <DiscountCardIssueTable data={discountCard} searchInfo={searchInfo} handleChange={handleChange}/>
            </InfiniteScroll>
        </DashboardLayout>
    )
}
