/**
 *
 * @param {string} string
 * @param {number} limit
 * @return {string | null}
 */
export default function ellipsis(string, limit) {
    let dots = "…";
    if (string) {
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }
    return null
};
