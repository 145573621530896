/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {getProductCategory} from "../../api/routes";
import Loading from "../../components/Loading";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default function NewProductCategoryFields({method, information, setInformation, submit,handleUpdate}) {
    const [loading, setLoading] = useState(false);
    const [productCategory, setProductCategory] = useState([]);
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };

    useEffect(() => {
        setLoading(true);
        getProductCategory(true,(isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            }  else {
                setLoading(false);
                setProductCategory(data);
            }
        });
    }, []);

    return (
        <>
            <Loading loading={loading}/>
            <SoftBox py={3}>
                <SoftBox component="form" role="form">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    دسته بندی محصولات
                                </SoftTypography>
                            </SoftBox>
                            <Select name="parent_id" value={String(information.parent_id)} onChange={handleChange}
                                    fullWidth>
                                {productCategory.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    نام
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput name="name" value={information.name} onChange={handleChange}
                                       type="text" placeholder="نام"/>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    توضیحات
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput name="description" value={information.description} onChange={handleChange}
                                       type="text" placeholder="توضیحات"/>
                        </Grid>
                    </Grid>
                    {method !== "update" ?
                        <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                            ثبت
                        </SoftButton> :
                        <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                            آپدیت
                        </SoftButton>}
                </SoftBox>
            </SoftBox>
        </>
    )
}
