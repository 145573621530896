/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../../functions/fixTableCellWidth";
function createData(id, amount, balance,typeName, ipAddress, createdAt) {
    return {
        id,
        amount,
        balance,
        typeName,
        ipAddress,
        createdAt,
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left"
                           style={{...fixTableCellWidth("170px"), paddingLeft: 25}}>{row.amount}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.balance}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.typeName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.ipAddress}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.createdAt}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function WalletTransactionsTable({data}) {
    const rows = data.map(item => createData(item.id, item?.amount, item?.balance,item?.type_name, item.ip_address, item.created_at
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>مبلغ تراکنش</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>موجودی کیف پول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نوع</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>IP</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>تاریخ ایجاد</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
