/* eslint-disable react/prop-types */
import ModalUi from 'components/ModalUI'
import React from 'react'
import SimpleImageSlider from "react-simple-image-slider";

/**
 *
 * @param {{state:boolean,data:{url: string}[] | string[]}} image
 * @param {function} closeImage
 * @return {JSX.Element}
 *
 */
export default function ImageModal({image, closeImage}) {
    return (
        <ModalUi open={image.state} handleClose={closeImage}>
            {image?.data.length > 0 ?
                <SimpleImageSlider style={{cursor: "pointer"}} onClick={(idx) => window.open(image.data[idx], "_blank")}
                                   width={600}
                                   height={300}
                                   images={image?.data?.map((item) => ({url: item}))}
                                   showBullets={image?.data.length !== 1}
                                   showNavs={image?.data.length !== 1}
                /> : <p style={{fontSize: 16}}>هیج تصویری ثبت نشده است!</p>}
        </ModalUi>
    )
}
