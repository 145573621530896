import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getSingleBanner, newBanner, updateBanner } from "../../api/routes";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import randomToken from "../../functions/randomToken";
import NewBannerFields from "./NewBannerFields";

export default function NewBanner() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const bannerId = searchParams.get("bannerId")

    let schema = yup.object().shape({
        lat: yup.string(),
        long: yup.string(),
        description: yup.string(),
        city_id: yup.string(),
        area_id: yup.string(),
        type: yup.string(),
        name: yup.string().required(),
        order: yup.string().required(),
        is_active: yup.string().required(),
        image: yup.mixed(),
    });
    useEffect(() => {
        if (method === "update" && bannerId) {
            setLoading(true);
            getSingleBanner(bannerId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {
                        city_id,
                        name,
                        description,
                        area_id,
                        type,
                        order,
                        long,
                        lat,
                        is_active
                    } = data;
                    setInformation({
                        name,
                        description,
                        city_id,
                        area_id,
                        type,
                        order,
                        is_active: +is_active,
                        position: {
                            lat: lat,
                            lng: long
                        },
                    })

                }
            })
        }
    }, [bannerId]);
    const submitNewBanner = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();
                    const { image, position, ...restInformation } = information;
                    for (let item in restInformation) {
                        if (restInformation[item])
                            formdata.append(item, restInformation[item]);
                    }
                    formdata.append("is_root", "0");

                    if (position && position.lat) {
                        formdata.append("lat", position.lat);
                        formdata.append("long", position.lng);
                    }
                    if (image.length > 0 &&  image[0] instanceof File) {
                        const compressedImage = await imageCompression(image[0], { maxSizeMB: 2 });
                        formdata.append(`image`, compressedImage);
                    }

                    setLoading(true);
                    newBanner(formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(`/banner?update=${randomToken()}`);
                        }
                    })
                }
            }).catch(err => {
                return toast.error(err.errors[0])
            })
    }

    const submitUpdateBanner = async () => {
        let formdata = new FormData();
        const { image, position, ...restInformation } = information;
        setLoading(true);
        for (let item in restInformation) {
            if (restInformation[item])
                formdata.append(item, restInformation[item]);
        }

        if (position && position.lat) {
            formdata.append("lat", position.lat);
            formdata.append("long", position.lng);
        }
        if (image.length > 0 &&  image[0] instanceof File) {
            const compressedImage = await imageCompression(image[0], { maxSizeMB: 2 });
            formdata.append(`image`, compressedImage);
        }

        updateBanner(formdata, bannerId, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/banner?update=${randomToken()}`);
            }
        })

    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Loading loading={loading} />
            <NewBannerFields information={information} setInformation={setInformation} submit={submitNewBanner}
                method={method} handleUpdate={submitUpdateBanner} />
        </DashboardLayout>
    )
}
