/* eslint-disable react/prop-types */
import * as React from "react";
import ModalUi from "../../components/ModalUI";
import {Alert} from "@mui/material";

export default function MoreInfoModal({modalInfo, closeModal}) {
    return(
        <ModalUi open={modalInfo.state} handleClose={closeModal} style={{minWidth: "600px"}}>
            <Alert severity="info" sx={{mb:1}}>نام کاربری: {modalInfo.info?.user?.username}</Alert>
            <Alert severity="info" variant={"outlined"} sx={{mb:1}}>امتیاز: {modalInfo.info.points}</Alert>
            <Alert severity="info" sx={{mb:1}}>موجودی: {modalInfo.info?.user?.balance}</Alert>
            <Alert severity="info" variant={"outlined"} sx={{mb:1}}>شهر: {modalInfo.info?.city?.name}</Alert>
            <Alert severity="info" sx={{mb:1}}>آدرس: {modalInfo.info.address}</Alert>
            <Alert severity="info" variant={"outlined"} sx={{mb:1}}>آخرین تاریخ فعالیت: {new Date(modalInfo.info.last_active_at).toLocaleString('fa-IR')}</Alert>
            <Alert severity="info" sx={{mb:1}}>تاریخ ایجاد: {new Date(modalInfo.info.created_at).toLocaleString('fa-IR')}</Alert>
        </ModalUi>
    )
}
