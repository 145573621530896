/* eslint-disable react/prop-types */
import {MapContainer, Marker, TileLayer, useMapEvents} from "react-leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-defaulticon-compatibility"
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css"
import icon from 'leaflet/dist/images/marker-icon.png';

import { Icon } from 'leaflet'
const myIcon = new Icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
})
export default function LeafletMap({selectedPosition, setSelectedPosition, zoom, style, isReadOnly = false,zoomControl = true}) {
    const LocationFinderDummy = () => {
        useMapEvents({
            click(e) {
                setSelectedPosition(e.latlng);
            },
        });
        return null;
    };

    return <MapContainer zoomControl={zoomControl} style={style} center={{lat: selectedPosition?.lat ??36.297610076585485, lng:selectedPosition?.lng?? 59.60661556379091}}
                         zoom={zoom}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Object(selectedPosition).hasOwnProperty("lat") && <Marker position={selectedPosition} icon={myIcon}/>}
        {!isReadOnly && <LocationFinderDummy/>}
    </MapContainer>
}
