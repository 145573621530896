/* eslint-disable react/prop-types */
import ModalUi from "../../../../components/ModalUI";
import {useEffect, useState} from "react";
import {getCity, getStats} from "../../../../api/routes";
import queryString from "../../../../functions/queryString";
import {toast} from "react-toastify";
import CitiesTable from "./CitiesTable";
import Loading from "../../../../components/Loading";

export default function CitiesModal({cities, closeCities}) {
    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState([]);
    const [stats, setStats] = useState([]);

    useEffect(() => {
        if (cities?.countryId !== undefined) {
            const query = {
                country_id: cities?.countryId,
            }
            setLoading(true);
            getCity(queryString(query), false, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setCity(data)
                }
            })
        }
    }, [cities?.countryId]);
    useEffect(() => {
        const query = {
            model_type: 'App\\Models\\City'
        }
        setLoading(true);
        getStats(queryString(query), (isOk, data) => {
            setLoading(false);
            if (!isOk) {
                return toast.error(data.message)
            } else {
                setStats(data)
            }
        })
    }, []);
    return (
        <ModalUi open={cities.state} handleClose={closeCities} style={{minWidth: "400px"}}>
            <Loading loading={loading}/>
            <CitiesTable data={city} countryId={cities?.countryId} stats={stats}/>
        </ModalUi>
    )
}
