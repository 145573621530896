/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import SoftBadge from "../../components/SoftBadge";
import {useNavigate} from "react-router-dom";
import useModal from "../../hooks/useModal";
import CitiesModal from "./Modal/cities";
import {deleteCountry} from "../../api/routes";
import {toast} from "react-toastify";
import {useState} from "react";
import Loading from "../../components/Loading";

function createData(id, name, isoCode, phoneCode, nationality, operation) {
    return {
        id,
        name,
        isoCode,
        phoneCode,
        nationality,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={{...fixTableCellWidth("200px"), paddingLeft: 30}}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.isoCode}
                           style={fixTableCellWidth("160px")}>{row.isoCode}</TableCell>
                <TableCell className="tablecell" align="left" title={row.phoneCode}
                           style={fixTableCellWidth("160px")}>{row.phoneCode}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.nationality}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CountryTable({data}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [modals, updateModal, closeModal] = useModal({
        cities: {},
    })
    const closeCities = () => closeModal('cities');
    const handleDeleteCountry = (countryId) => {
        let answer = window.confirm("Are you sure about deleting country?");
        if (answer) {
            setLoading(true);
            deleteCountry(countryId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                    window.location.reload()
                }
            })
        } else {
        }
    }
    const rows = data.map(item => createData(item.id, item.name, item?.iso_code, item?.phone_code,
        item?.nationality, <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       badgeContent="شهر ها"
                       onClick={() => updateModal("cities", {state: true, countryId: item.id})}
                       color="info" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => handleDeleteCountry(item.id)}
                       badgeContent="حذف"
                       color="error" size="xs"/>
        </section>
    ))

    return (
        <>
            <Loading loading={loading}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained"
                                   onClick={() => navigate("/new-country")}
                                   badgeContent="کشور جدید"
                                   style={{cursor: "pointer"}}
                                   color="success" size="xs"/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("180px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>Iso code</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>Phone code</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>ملیت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.cities.state && <CitiesModal cities={modals.cities} closeCities={closeCities}/>}
        </>
    );
}
