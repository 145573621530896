/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addDiscountCardDesign, updateDiscountCardDesign } from "../../../api/routes";
import Loading from "../../../components/Loading";
import ModalUi from "../../../components/ModalUI";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftInput from "../../../components/SoftInput";
import SoftTypography from "../../../components/SoftTypography";

export default function NewDesignModal({ modalInfo, closeModal, setDiscountCardDesign }) {
  const [information, setInformation] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInformation({ ...information, [name]: value });
  };
  const handleImageChange = (event) => {
    const { name, files } = event.target;
    setInformation({ ...information, [name]: files });
  };
  useEffect(() => {
    if (modalInfo?.data) {
      setInformation({ color: modalInfo?.data.color, savedBackground: modalInfo?.data.background })
    }
  }, [modalInfo?.data])

  const handleNewDesign = async () => {
    let formdata = new FormData();
    setLoading(true);
    if (information.color)
      formdata.append("color", information.color);
    formdata.append("size", "1");
    formdata.append("discount_card_design_text_id", "1");
    if (information.background && information.background.length > 0 && information.background[0] instanceof File) {
      const compressedImage = await imageCompression(information.background[0], { maxSizeMB: 2 });
      formdata.append(`background`, compressedImage);
    }
    addDiscountCardDesign(formdata, (isOk, data) => {
      setLoading(false);
      if (!isOk) {
        return toast.error(data.message);
      } else {
        toast.success("Your request has been submitted");
        setDiscountCardDesign((prev) => [data, ...prev]);
        closeModal()
      }
    });
  };

  const handleUpdateDesign = async () => {
    if (modalInfo?.data) {
      let formdata = new FormData();
      setLoading(true);
      if (information.color)
        formdata.append("color", information.color);

      if (information.background && information.background.length > 0 && information.background[0] instanceof File) {
        const compressedImage = await imageCompression(information.background[0], { maxSizeMB: 2 });
        formdata.append(`background`, compressedImage);
      }
      updateDiscountCardDesign(modalInfo?.data.id, formdata, (isOk, data) => {
        setLoading(false);
        if (!isOk) {
          return toast.error(data.message);
        } else {
          toast.success("Your request has been submitted");
          setDiscountCardDesign((prev) => 
            prev.map(item => {
              if (item.id === data.id)
                return data;
              else return item
            })
          );
          closeModal()
        }
      });
    }
  };

  return (
    <ModalUi open={modalInfo.state} handleClose={closeModal} style={{ minWidth: "600px" }}>
      <SoftBox py={3}>
        <Loading loading={loading} />
        <SoftBox component="form" role="form">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} xl={12}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  رنگ
                </SoftTypography>
              </SoftBox>
              <SoftInput name="color" value={information.color} onChange={handleChange}
                type="text" placeholder="رنگ" />
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  پس زمینه
                </SoftTypography>

              </SoftBox>
              <input name="background" onChange={handleImageChange} type="file" style={{
                background: "white",
                padding: "8px 12px",
                width: "100%",
                border: "0.0625rem solid #d2d6da",
                borderRadius: "0.5rem",
                color: "#495057",
              }} placeholder="Images" accept=".jpg,.jpeg,.png" />
              {information.savedBackground && <img src={information.savedBackground} style={{ width: 100, marginTop: 5 }} />}
            </Grid>
          </Grid>
        </SoftBox>
        {modalInfo.isUpdate ? <SoftButton onClick={handleUpdateDesign} style={{ marginTop: 20 }} variant="gradient" color="info">
          ویرایش
        </SoftButton>
          : <SoftButton onClick={handleNewDesign} style={{ marginTop: 20 }} variant="gradient" color="info">
            ثبت
          </SoftButton>}
      </SoftBox>
    </ModalUi>
  );
}
