import * as React from "react";
import QRCode from "react-qr-code";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import SoftButton from "../../components/SoftButton";
import Loading from "../../components/Loading";

export default function MugsQRCodesPreview() {
  const { state } = useLocation();
  const navigate = useNavigate()

  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (state)
      setData(state);
  }, [state]);
  const handleAfterPrint = React.useCallback(() => {
    setLoading(false);
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setLoading(true);
  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "MUGS",
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={loading} />
      <SoftButton style={{ marginLeft: 10 }} variant="gradient"
                  onClick={() => navigate('/mug')} circular
                  size={"small"} color="info">
        بازگشت
      </SoftButton>
      <SoftButton variant="gradient"
                  onClick={handlePrint} circular
                  size={"small"} color="warning">
        چاپ
      </SoftButton>
      <div ref={componentRef}>
        {data.map(item => <QRCode
          key={item.id}
          size={256}
          value={item?.uuid}
          viewBox={`0 0 256 256`}
          style={{ padding: 4, margin: 20, backgroundColor: "white" }}
        />)}
      </div>
    </DashboardLayout>
  );
}
