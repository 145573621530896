import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
axios.defaults.withXSRFToken = true
axios.defaults.withCredentials = true;
export const createAxiosInstance = (contentType) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Accept-Language': 'fa',
            'Content-Type': contentType,
            'Accept': 'application/json',
        }
    });

    axiosInstance.interceptors.request.use(onRequest, null);
    return axiosInstance;
};
const setCSRFToken = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL.split("/").splice(0, 3).join("/")}/sanctum/csrf-cookie`, {
        withCredentials: true
    }).then(() => cookies.set('is_access', "aXNfYWNjZXNzOnRydWU", { path: '/', maxAge: 2592000 }));
};

export const onRequest = (config) => {
    if ((config.method === 'post' || config.method === 'put' || config.method === 'delete') && !cookies.get('XSRF-TOKEN')) {
        return setCSRFToken().then(response => config);
    }
    return config;
};
