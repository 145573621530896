import {useEffect, useState} from "react";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loading from "../../../components/Loading";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import {getSingleBusinessCategory, newBusinessCategory, updateBusinessCategory} from "../../../api/routes";
import NewBusinessCategoryFields from "./NewBusinessCategoryFields";
import queryString from "../../../functions/queryString";

export default function NewBusinessCategory() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const method = searchParams.get("method")
    const businessCategoryId = searchParams.get("categoryId")

    let schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        parent_id: yup.number()
    });

    useEffect(() => {
        if (method === "update" && businessCategoryId) {
            setLoading(true);
            getSingleBusinessCategory(businessCategoryId, true, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setInformation({
                        parent_id: data.parent_id,
                        name: data?.name ?? "",
                        description: data?.description ?? "",
                    })

                }
            })
        }
    }, [businessCategoryId]);

    const submit = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();

                    formdata.append("name", information?.name);
                    if (information?.parent_id)
                        formdata.append("parent_id", information?.parent_id);

                    if (information?.description)
                        formdata.append("description", information?.description);


                    setLoading(true);
                    newBusinessCategory(formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(`/business-category`, {state: {forceUpdate: true}})
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }

    const handleUpdate = () => {
        const query = {
            name: information?.name,
            description: information?.description,
            parent_id: information?.parent_id,
        };

        setLoading(true);
        updateBusinessCategory(businessCategoryId, queryString(query), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/business-category`, {state: {forceUpdate: true}})
            }
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <NewBusinessCategoryFields handleUpdate={handleUpdate} method={method} information={information}
                                      setInformation={setInformation}
                                      submit={submit}/>
        </DashboardLayout>
    )
}
