/* eslint-disable react/prop-types */
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default function NewCountryFields({information, setInformation,submit}) {
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    return (
        <SoftBox py={3}>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Iso code
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="iso_code" value={information.iso_code} onChange={handleChange}
                                   type="text" placeholder="Iso code"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Phone code
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="phone_code" value={information.phone_code} onChange={handleChange}
                                   type="text" placeholder="Phone code"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ملیت
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="nationality" value={information.nationality} onChange={handleChange}
                                   type="text" placeholder="ملیت"/>
                    </Grid>
                </Grid>
                <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                    ثبت
                </SoftButton>
            </SoftBox>
        </SoftBox>
    )
}
