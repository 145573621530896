import { getAxiosInstanceApi, getAxiosMultiPartInstanceApi } from "./api";
import { cachiosInstance } from "./cacheApi";

/**
 * ----------------------------
 *  Auth routes
 * ----------------------------
 */
export const loginRoute = (data, callback) => {
  getAxiosInstanceApi().post("/auth/login", data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const logoutUser = (callback) => {
  getAxiosInstanceApi().post("/auth/logout")
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getMe = (callback) => {
  cachiosInstance.get("/auth/me")
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response);
    });
};
/**
 * ----------------------------
 *  Banner routes
 * ----------------------------
 */
export const getBanner = (query, callback, force = false) => {
  cachiosInstance.get(`/banner?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleBanner = (bannerId, callback) => {
  cachiosInstance.get(`/banner/${bannerId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteBanner = (bannerId, callback) => {
  getAxiosInstanceApi().delete(`/banner/${bannerId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newBanner = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/banner`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateBanner = (data, bannerId, callback) => {
  getAxiosMultiPartInstanceApi().post(`/banner/${bannerId}?_method=PATCH`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Business routes
 * ----------------------------
 */
export const getBusiness = (query, force = false, callback) => {
  cachiosInstance.get(`/business?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getBusinessDocuments = (businessId, callback) => {
  cachiosInstance.get(`/business/${businessId}/document`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleBusiness = (businessId, callback) => {
  getAxiosInstanceApi().get(`/business/${businessId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getPersonOfBusiness = (businessId, query, callback) => {
  cachiosInstance.get(`/business/${businessId}/person?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};

export const updateBusiness = (data, businessID, callback) => {
  getAxiosMultiPartInstanceApi().post(`/business/${businessID}?_method=PATCH`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newBusiness = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/business`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};

/**
 * ----------------------------
 *  Business category routes
 * ----------------------------
 */
export const getBusinessCategory = (force = false, callback) => {
  cachiosInstance.get(`/business-category`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteBusinessCategory = (companyBusinessId, callback) => {
  getAxiosInstanceApi().delete(`/business-category/${companyBusinessId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newBusinessCategory = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/business-category`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleBusinessCategory = (businessCategoryId, force = false, callback) => {
  cachiosInstance.get(`/business-category/${businessCategoryId}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateBusinessCategory = (businessCategoryId, query, callback) => {
  getAxiosInstanceApi().patch(`/business-category/${businessCategoryId}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getBusinessMenu = (businessId, query, force = false, callback) => {
  cachiosInstance.get(`/business/${businessId}/menu-section?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newBusinessMenu = (businessId, data, callback) => {
  getAxiosInstanceApi().post(`/business/${businessId}/menu-section`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};

/**
 * ----------------------------
 *  Menu section routes
 * ----------------------------
 */

export const getSingleMenuSection = (menuId, force = false, callback) => {
  cachiosInstance.get(`/menu-section/${menuId}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateSingleMenuSection = (menuId, data, callback) => {
  getAxiosInstanceApi().post(`/menu-section/${menuId}?_method=PATCH`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteMenuSection = (menuId, callback) => {
  getAxiosInstanceApi().delete(`/menu-section/${menuId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};

/**
 * ----------------------------
 *  Customer routes
 * ----------------------------
 */
export const getCustomer = (query, callback, force = false) => {
  cachiosInstance.get(`/customer?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Product tag routes
 * ----------------------------
 */
export const getProductTag = (query, force = false, callback) => {
  cachiosInstance.get(`/product-tag?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteProductTag = (product_tag_id, callback) => {
  getAxiosInstanceApi().delete(`/product-tag/${product_tag_id}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newProductTag = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/product-tag`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateProductTag = (product_tag_id, query, callback) => {
  getAxiosInstanceApi().patch(`/product-tag/${product_tag_id}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Rating factor routes
 * ----------------------------
 */
export const getRatingFactor = (query, force = false, callback) => {
  cachiosInstance.get(`/rating-factor?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleRatingFactor = (rating_factor_id, callback) => {
  cachiosInstance.get(`/rating-factor/${rating_factor_id}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newRatingFactor = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/rating-factor`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateRatingFactor = (rating_factor_id, query, callback) => {
  getAxiosInstanceApi().patch(`/rating-factor/${rating_factor_id}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteRatingFactor = (rating_factor_id, callback) => {
  getAxiosInstanceApi().delete(`/rating-factor/${rating_factor_id}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Qrcode routes
 * ----------------------------
 */
export const getQrcode = (query, callback, force = false) => {
  cachiosInstance.get(`/q-r-code?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleQrcode = (QRCodeId, callback) => {
  cachiosInstance.get(`/q-r-code/${QRCodeId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteQrcode = (qrcodeId, callback) => {
  getAxiosInstanceApi().delete(`/q-r-code/${qrcodeId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newQrcode = (query, callback) => {
  getAxiosInstanceApi().post(`/q-r-code?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateQrcode = (qrcodeId, query, callback) => {
  getAxiosInstanceApi().put(`/q-r-code/${qrcodeId}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Penalty routes
 * ----------------------------
 */
export const addPenalty = (query, callback) => {
  getAxiosInstanceApi().post(`/penalty?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getPenalty = (query, callback, force = false) => {
  cachiosInstance.get(`/penalty?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deletePenalty = (penaltyId, callback) => {
  getAxiosInstanceApi().delete(`/penalty/${penaltyId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Journey routes
 * ----------------------------
 */
export const getJourney = (query, callback, force = false) => {
  cachiosInstance.get(`/journey?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleJourney = (journeyId, query = "", callback) => {
  cachiosInstance.get(`/journey/${journeyId}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newJourney = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/journey`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteJourney = (journeyId, callback) => {
  getAxiosInstanceApi().delete(`/journey/${journeyId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateJourney = (journeyId, data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/journey/${journeyId}?_method=PATCH`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Product category routes
 * ----------------------------
 */
export const getProductCategory = (force = false, callback) => {
  cachiosInstance.get(`/product-category`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newProductCategory = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/product-category`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleProductCategory = (productCategoryId, force = false, callback) => {
  cachiosInstance.get(`/product-category/${productCategoryId}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateProductCategory = (productCategoryId, query, callback) => {
  getAxiosInstanceApi().patch(`/product-category/${productCategoryId}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteProductCategory = (productCategoryId, callback) => {
  getAxiosInstanceApi().delete(`/product-category/${productCategoryId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Country routes
 * ----------------------------
 */
export const getCountry = (query, force = false, callback) => {
  cachiosInstance.get(`/country?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteCountry = (countryId, callback) => {
  getAxiosInstanceApi().delete(`/country/${countryId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newCountry = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/country`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  City routes
 * ----------------------------
 */
export const getCity = (query, force = false, callback) => {
  cachiosInstance.get(`/city?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newCity = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/city`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteCity = (cityId, callback) => {
  getAxiosInstanceApi().delete(`/city/${cityId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Area routes
 * ----------------------------
 */
export const getArea = (query, force = false, callback) => {
  cachiosInstance.get(`/area?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteArea = (areaId, callback) => {
  getAxiosInstanceApi().delete(`/area/${areaId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newArea = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/area`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Discount card design routes
 * ----------------------------
 */
export const addDiscountCardDesign = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/discount-card-design`, data)
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateDiscountCardDesign = (discountCardDesignId, data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/discount-card-design/${discountCardDesignId}?_method=PATCH`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteDiscountCardDesign = (discountCardDesignId, callback) => {
  getAxiosInstanceApi().delete(`/discount-card-design/${discountCardDesignId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getDiscountCardDesign = (callback) => {
  cachiosInstance.get(`/discount-card-design`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Discount card routes
 * ----------------------------
 */
export const getDiscountCard = (query, callback, force = false) => {
  cachiosInstance.get(`/discount-card?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getDiscountCardIssue = (query, callback, force = false) => {
  cachiosInstance.get(`/discount-card-issue?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};

export const getSingleDiscountCardIssue = (discountCardIssueId, callback) => {
  getAxiosInstanceApi().get(`/discount-card-issue/${discountCardIssueId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getUpdateDiscountCardIssue = (query, discountCardIssueId, callback) => {
  getAxiosInstanceApi().put(`/discount-card-issue/${discountCardIssueId}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Wallet transactions routes
 * ----------------------------
 */
export const getWalletTransactions = (query, callback) => {
  cachiosInstance.get(`/wallet-transaction?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Feature routes
 * ----------------------------
 */
export const getFeature = (callback) => {
  cachiosInstance.get(`/feature`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newFeature = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/feature`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleFeature = (featureId, callback) => {
  getAxiosInstanceApi().get(`/feature/${featureId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateFeature = (featureId, query, callback) => {
  getAxiosInstanceApi().put(`/feature/${featureId}?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const deleteFeature = (featureId, callback) => {
  getAxiosInstanceApi().delete(`/feature/${featureId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};

/**
 * ----------------------------
 *  Person routes
 * ----------------------------
 */
export const getPerson = (query, callback) => {
  cachiosInstance.get(`/person?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getBusinessOfPerson = (personId, query, callback) => {
  cachiosInstance.get(`/person/${personId}/business?${query}`)
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Mug routes
 * ----------------------------
 */
export const getMug = (query, callback, force = false) => {
  cachiosInstance.get(`/mug?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data);
    }).catch(error => {
      console.log(error);
      callback(false, error?.response?.data);
    });
};
export const getMugSponsors = (callback, force = false) => {
  cachiosInstance.get(`/mug/sponsors`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const newMug = (data, callback) => {
  getAxiosMultiPartInstanceApi().post(`/mug`, data)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const updateMug = (mugId, data, callback) => {
  getAxiosInstanceApi().put(`/mug/${mugId}?${data}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
export const getSingleMug = (mugId, callback) => {
  cachiosInstance.get(`/mug/${mugId}`)
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
/**
 * ----------------------------
 *  Stats routes
 * ----------------------------
 */
export const getStats = (query, callback, force = false) => {
  cachiosInstance.get(`/stats?${query}`, { force })
    .then(response => {
      const data = response.data;
      callback(true, data.data);
    }).catch(error => {
      callback(false, error?.response?.data);
    });
};
