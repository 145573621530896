import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import queryString from "../../functions/queryString";
import {getCustomer} from "../../api/routes";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomerTable from "./CustomerTable";

export default function Customer() {
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [searchInfo, setSearchInfo] = useState({});
    const [trigger, setTrigger] = useState(null);
    const handleChange = (event) => {
        const {name, value} = event.target;
        setSearchInfo({[name]: value});
    };
    const dateHandleChange = (value, name) => {
        setSearchInfo({
            ...searchInfo,
            [name]: `${value.$d.getUTCFullYear()}-${String(value.$d.getUTCMonth() + 1).padStart(2, "0")}-${String(value.$d.getUTCDate()).padStart(2, "0")}`
        });
    };
    const handleClickFilter = () => {
        if (page === 0)
            setTrigger(Math.random());
        else
            setPage(0);

        setCursor(null);
    }
    useEffect(() => {
        setLoading(true);
        const query = {
            cursor: cursor === null ? null : cursor,
            full_name: searchInfo?.full_name ? searchInfo?.full_name : null,
            username: searchInfo?.username ? searchInfo?.username: null,
            phone_number: searchInfo?.phone_number ? searchInfo?.phone_number: null,
            last_active_from: searchInfo?.last_active_from ? searchInfo?.last_active_from: null,
            last_active_to: searchInfo?.last_active_to ? searchInfo?.last_active_to: null,
        }
        getCustomer(queryString(query), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                if (page !== 0) {
                    setCustomer((prev) => ([...prev, ...data.data]));
                } else {
                    setCustomer(data.data);
                }
                setLoading(false);
                setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                if (data.meta.next_cursor !== null)
                    return setHasMore(true);

                else return setHasMore(false)
            }
        }, trigger !== null)

    }, [page, trigger]);

    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <InfiniteScroll dataLength={customer.length} next={fetchMoreData} hasMore={hasMore}>
                <CustomerTable data={customer} searchInfo={searchInfo} handleFilterChange={handleChange}
                               handleClickFilter={handleClickFilter} dateHandleChange={dateHandleChange}/>
            </InfiniteScroll>
        </DashboardLayout>
    )
}
