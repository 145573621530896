/* eslint-disable react/prop-types */
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import {useEffect, useState} from "react";
import {getArea, getCity} from "../../api/routes";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";
import {Select, Stack, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import queryString from "../../functions/queryString";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";

export default function NewJourneyFields({method, information, setInformation, handleUpdate, submit}) {
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    const handleImageChange = (event) => {
        const {name, files} = event.target;
        setInformation({...information, [name]: files})
    };
    const dateHandleChange = (value, name) => {
        setInformation({
            ...information,
            [name]: `${value.$d.getUTCFullYear()}-${String(value.$d.getUTCMonth() + 1).padStart(2, "0")}-${String(value.$d.getUTCDate()).padStart(2, "0")}`
        });
    };
    useEffect(() => {
        const query = {
            country_id: 1
        }
        setLoading(true);
        getCity(queryString(query), true, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                setCity(data);
            }

        });
    }, [])

    useEffect(() => {
        if (information?.city_id !== undefined) {
            const query = {
                city_id: information?.city_id,
            }
            setLoading(true);
            getArea(queryString(query), false, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setArea(data)
                }
            })
        }
    }, [information?.city_id]);

    return (
        <SoftBox py={3}>
            <Loading loading={loading}/>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                توضیحات
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="description" value={information.description} onChange={handleChange}
                                   type="text" placeholder="توضیحات"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                درصد پیشرفت
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="ppercent" value={information.ppercent} onChange={handleChange}
                                   type="text" placeholder="درصد پیشرفت (بین 0 تا 1)"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ضریب پیشرفت
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="pvalue" value={information.pvalue} onChange={handleChange}
                                   type="text" placeholder="ضریب پیشرفت"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شهر
                            </SoftTypography>
                        </SoftBox>
                        <Select name="city_id" value={String(information.city_id)} onChange={handleChange}
                                fullWidth
                                placeholder='شهر'>
                            {city.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                منطقه
                            </SoftTypography>
                        </SoftBox>
                        <Select name="area_id" value={String(information.area_id)} onChange={handleChange}
                                fullWidth
                                placeholder='منطقه'>
                            {area.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    {method === "update" && <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                وضعیت نمایش
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_active" value={String(information.is_active)} onChange={handleChange}
                                fullWidth
                                placeholder='Is active'>
                            <MenuItem value={"1"}>فعال</MenuItem>
                            <MenuItem value={"0"}>غیرفعال</MenuItem>
                        </Select>
                    </Grid>}
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                براساس ترتیب
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_order_based" value={String(information.is_order_based)} onChange={handleChange}
                                fullWidth>
                            <MenuItem value={"1"}>بله</MenuItem>
                            <MenuItem value={"0"}>خیر</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نیاز به اتمام
                            </SoftTypography>
                        </SoftBox>
                        <Select name="need_complete" value={String(information.need_complete)} onChange={handleChange}
                                fullWidth>
                            <MenuItem value={"1"}>بله</MenuItem>
                            <MenuItem value={"0"}>خیر</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تاریخ شروع
                            </SoftTypography>
                        </SoftBox>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={information.start_at}
                                    onChange={(newValue) => dateHandleChange(newValue, "start_at")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تاریخ پایان
                            </SoftTypography>
                        </SoftBox>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={information.end_at}
                                    onChange={(newValue) => dateHandleChange(newValue, "end_at")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                عکس
                            </SoftTypography>

                        </SoftBox>
                        <input name="image" onChange={handleImageChange} type="file" style={{
                            background: 'white',
                            padding: '8px 12px',
                            width: '100%',
                            border: '0.0625rem solid #d2d6da',
                            borderRadius: '0.5rem',
                            color: '#495057'
                        }} placeholder="Images" accept=".jpg,.jpeg,.png"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                دسته بندی
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="category" value={information.category} onChange={handleChange}
                                   type="text" placeholder="دسته بندی"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                پوستر
                            </SoftTypography>
                        </SoftBox>
                        <input name="poster" onChange={handleImageChange} type="file" style={{
                            background: 'white',
                            padding: '8px 12px',
                            width: '100%',
                            border: '0.0625rem solid #d2d6da',
                            borderRadius: '0.5rem',
                            color: '#495057'
                        }} placeholder="Poster" accept=".jpg,.jpeg,.png"/>
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        آپدیت
                    </SoftButton>}
            </SoftBox>
        </SoftBox>
    )
}
