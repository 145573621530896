import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import * as React from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import * as yup from "yup";
import NewPenaltyFields from "./NewPenaltyFields";
import {addPenalty} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import randomToken from "../../functions/randomToken";
import {useNavigate, useSearchParams} from "react-router-dom";
import SoftTypography from "../../components/SoftTypography";

export default function NewPenalty() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id")
    const customerName = searchParams.get("customer_name")

    let schema = yup.object().shape({
        customer_id: yup.string().required(),
        due_at: yup.string().required(),
        points_amount: yup.number(),
        wallet_amount: yup.number(),
        points_penalty: yup.number(),
        wallet_penalty: yup.number(),
    });
    useEffect(() => {
        if (customerId !== null)
            setInformation({...information, customer_id:customerId});
    }, [customerId]);

    const submitPenalty = () => {
        schema.validate(information)
            .then(async () => {
                let data = {}
                const {phone_number, ...restInformation} = information;
                for (let item in restInformation) {
                    if (information[item])
                        data[item] = information[item];
                }
                setLoading(true);
                addPenalty(queryString(data), (isOk, data) => {
                    if (!isOk) {
                        setLoading(false);
                        return toast.error(data.message)
                    } else {
                        setLoading(false);
                        toast.success("Your request has been submitted");
                        navigate(`/penalty?update=${randomToken()}`);
                    }
                })
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            {customerName && <SoftTypography component="label" variant="caption" fontWeight="bold" color={"primary"}>
                اعمال جریمه برای {customerName}
            </SoftTypography>}
            <NewPenaltyFields information={information} setInformation={setInformation} submit={submitPenalty}/>
        </DashboardLayout>
    )
}
