/* eslint-disable react/prop-types */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import randomToken from 'functions/randomToken';
import * as React from 'react';
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteQrcode } from "../../api/routes";
import Loading from "../../components/Loading";
import SoftBadge from "../../components/SoftBadge";
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import separate from "../../functions/separate";
import useModal from "../../hooks/useModal";
import QRInfoModal from "./QRInfoModal";

function createData(id, name, total, remaining, pointAmount, walletAmount, journeyName, isActive, createdAt, operation) {
    return {
        id,
        name,
        total,
        remaining,
        pointAmount,
        walletAmount,
        journeyName,
        isActive,
        createdAt,
        operation
    };
}

function Row(props) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("180px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("150px")}>{row.journeyName}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("110px")}>{row.total}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("120px")}>{row.remaining}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("120px")}>{row.pointAmount}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("110px")}>{separate(row.walletAmount)}</TableCell>
                <TableCell className="tablecell" align="left" title={new Date(row.createdAt).toLocaleString('fa-IR')}
                    style={fixTableCellWidth("120px")}>{new Date(row.createdAt).toLocaleString('fa-IR')}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("100px")}>{row.isActive ? "فعال" : "غیرفعال"}</TableCell>
                <TableCell className="tablecell" align="left"
                    style={fixTableCellWidth("150px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function QrcodeTable({ data }) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [modals, updateModal, closeModal] = useModal({
        qrcode: { state: false },
    })
    const [searchParams] = useSearchParams()

    const journey_id = searchParams.get("journey_id")

    const closeQRCode = () => closeModal('qrcode');
    const handleDeleteQR = (qrId) => {
        let answer = window.confirm("آیا از حذف اطمینان دارید؟");
        if (answer) {
            setLoading(true);
            deleteQrcode(qrId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                }
            })
        }
    }
    const rows = data.map(item => createData(item.id, item.name, item?.total, item?.remaining,
        item?.points_amount, item?.wallet_amount, item?.journey?.name, item.is_active, item?.created_at,
        <section style={{ display: "flex", cursor: 'pointer' }}>
            <SoftBadge variant="contained"
                badgeContent="مشاهده QR"
                onClick={() => updateModal("qrcode", { state: true, QRInfo: item })}
                color="primary" size="xs" />
            <SoftBadge variant="contained"
                badgeContent="ویرایش"
                onClick={() => navigate(`/new-qr-code?method=update&qrcodeId=${item.id}`)}
                color="secondary" size="xs" />
            <SoftBadge variant="contained"
                badgeContent="حذف"
                onClick={() => handleDeleteQR(item.id)}
                color="error" size="xs" />
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <Loading loading={loading} />
                    <TableHead>
                        <SoftBadge onClick={() => navigate("/new-qr-code")} style={{ cursor: "pointer" }}
                            variant="contained"
                            badgeContent="QR جدید"
                            color="success" size="sm" />
                        {journey_id && <SoftBadge onClick={() => navigate(`/qrcode?update=${randomToken()}`)} style={{ cursor: "pointer" }}
                            variant="contained"
                            badgeContent="حذف فیلتر"
                            color="error" size="sm" />}
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام چالش</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>تعداد کل</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>باقی مانده</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>مقدار امتیاز</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>مقدار کیف
                                پول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>تاریخ ایجاد</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>وضعیت QR</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ display: 'block' }}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.qrcode.state && <QRInfoModal closeModal={closeQRCode} modalInfo={modals.qrcode} />}
        </>
    );
}
