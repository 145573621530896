/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import useModal from "../../hooks/useModal";
import SoftBadge from "../../components/SoftBadge";
import DiscountCardModal from "./discountCard/DiscountCardModal";
import WalletTransactionsModal from "./walletTransactions/WalletTransactionsModal";
import TableHeadItems from "./TableHeadItems";
import {useNavigate} from "react-router-dom";
import MoreInfoModal from "./MoreInfoModal";

function createData(id, name, phoneNumber, email,operation) {
    return {
        id,
        name,
        phoneNumber,
        email,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name} style={fixTableCellWidth("160px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.phoneNumber} style={fixTableCellWidth("190px")}>{row.phoneNumber}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("220px")}>{row.email}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("260px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CustomerTable({data,searchInfo, handleFilterChange,handleClickFilter,dateHandleChange}) {
    const navigate = useNavigate()
    const [modals, updateModal, closeModal] = useModal({
        discountCard: {state:false},
        walletTransactions: {state:false},
        moreInfo: {state:false},
    })
    const closeDiscountCardModal = () => closeModal('discountCard');
    const closeWalletTransactions = () => closeModal('walletTransactions');
    const closeMoreInfoModal = () => closeModal('moreInfo');

    const rows = data.map(item => createData(item.id, `${item?.user?.first_name} ${item?.user?.last_name}`, item?.user?.phone_number,
        item?.user?.email,
        <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       badgeContent="اطلاعات بیشتر"
                       onClick={() => updateModal("moreInfo", {state: true, info: item})}
                       color="info" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("discountCard", {state: true, customer_id: item.id})}
                       badgeContent="کارت های تخفیف"
                       color="success" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("walletTransactions", {state: true, user_id: item.user_id})}
                       badgeContent="تراکنش های کیف پول"
                       color="secondary" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => navigate(`/new-penalty?customer_id=${item.id}&customer_name=${item?.user?.first_name}-${item?.user?.last_name}`)}
                       badgeContent="جریمه"
                       color="error" size="xs"/>
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableHeadItems searchInfo={searchInfo} handleClickFilter={handleClickFilter} handleFilterChange={handleFilterChange} dateHandleChange={dateHandleChange}/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام و نام خانوادگی</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("190px")}>شماره موبایل</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("220px")}>ایمیل</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("260px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.discountCard.state && <DiscountCardModal modalInfo={modals.discountCard} closeModal={closeDiscountCardModal}/> }
            {modals.walletTransactions.state && <WalletTransactionsModal modalInfo={modals.walletTransactions} closeModal={closeWalletTransactions}/> }
            {modals.moreInfo.state && <MoreInfoModal modalInfo={modals.moreInfo} closeModal={closeMoreInfoModal}/> }
        </>
    );
}
