import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import Loading from "../../../components/Loading";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {getBusinessCategory} from "../../../api/routes";
import BusinessCategoryTable from "./BusinessCategoryTable";

export default function BusinessCategory() {
    const {state} = useLocation();
    const {forceUpdate} = state ?? [];
    const [loading, setLoading] = useState(false);
    const [businessCategory, setBusinessCategory] = useState([]);

    useEffect(() => {
        setLoading(true);
        getBusinessCategory(forceUpdate ?? false, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                setBusinessCategory(data);
            }
        })

    }, [forceUpdate]);

    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <BusinessCategoryTable data={businessCategory}/>
        </DashboardLayout>
    )
}
