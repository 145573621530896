/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import SoftBadge from "../../components/SoftBadge";
import {useNavigate} from "react-router-dom";
import {deletePenalty} from "../../api/routes";
import {toast} from "react-toastify";
import {useState} from "react";
import Loading from "../../components/Loading";

function createData(id, customerName, pointsAmount, pointsPenalty, walletAmount, walletPenalty, dueAt, finedAt, createdAt, operation) {
    return {
        id,
        customerName,
        pointsAmount,
        pointsPenalty,
        walletAmount,
        walletPenalty,
        dueAt,
        finedAt,
        createdAt,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left"
                           style={{...fixTableCellWidth("160px"), paddingRight: 30}}>{row.customerName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("120px")}>{row.pointsAmount}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("120px")}>{row.pointsPenalty}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("120px")}>{row.walletAmount}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("110px")}>{row.walletPenalty}</TableCell>
                <TableCell className="tablecell" align="left" title={new Date(row.dueAt).toLocaleString('fa-IR')}
                           style={fixTableCellWidth("110px")}>{new Date(row.dueAt).toLocaleString('fa-IR')}</TableCell>
                <TableCell className="tablecell" align="left" title={new Date(row.finedAt).toLocaleString('fa-IR')}
                           style={fixTableCellWidth("110px")}>{row.finedAt && new Date(row.finedAt).toLocaleString('fa-IR')}</TableCell>
                <TableCell className="tablecell" align="left" title={new Date(row.createdAt).toLocaleString('fa-IR')}
                           style={fixTableCellWidth("110px")}>{new Date(row.createdAt).toLocaleString('fa-IR')}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function PenaltyTable({data}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const handleDeletePenalty = (penaltyId) => {
        let answer = window.confirm("آیا از حذف اطمینان دارید؟");
        if (answer) {
            setLoading(true);
            deletePenalty(penaltyId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                    window.location.reload()
                }
            })
        }
    }
    const rows = data.map(item => createData(item.id, `${item?.customer?.user?.first_name} ${item?.customer?.user?.last_name}`, item?.points_amount, item?.points_penalty,
        item?.wallet_amount, item?.wallet_penalty, item?.due_at, item?.fined_at, item?.created_at,
        <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       badgeContent="حذف"
                       onClick={() => handleDeletePenalty(item.id)}
                       color="error" size="xs"/>
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <Loading loading={loading}/>
                    <TableHead>
                        <SoftBadge onClick={() => navigate("/new-penalty")} style={{cursor: "pointer"}}
                                   variant="contained"
                                   badgeContent="جریمه جدید"
                                   color="success" size="sm"/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("140px")}>نام مشتری</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>مقدار امتیاز</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>امتیاز جریمه</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>مقدار کیف
                                پول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>جریمه کیف
                                پول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>تاریخ اعمال</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>تاریخ جریمه</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>تاریخ ایجاد</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
