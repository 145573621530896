/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../../functions/fixTableCellWidth";
function createData(id, customerName, menuSectionName,productName, usedAt, expiresAt) {
    return {
        id,
        customerName,
        menuSectionName,
        productName,
        usedAt,
        expiresAt,
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left"
                           style={{...fixTableCellWidth("170px"), paddingLeft: 25}}>{row.customerName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("200px")}>{row.menuSectionName}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("200px")}>{row.productName}</TableCell>
                <TableCell className="tablecell" align="left" title={row.usedAt}
                           style={fixTableCellWidth("130px")}>{row.usedAt}</TableCell>
                <TableCell className="tablecell" align="left" title={row.expiresAt}
                           style={fixTableCellWidth("150px")}>{row.expiresAt}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function DiscountCardTable({data}) {
    const rows = data.map(item => createData(item.id, item?.business?.name, item?.menu_section?.name,item?.product?.name, item.used_at, item.expires_at
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام کسب و کار</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("200px")}>منو</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("200px")}>محصول</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>تاریخ استفاده</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>تاریخ ایجاد</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
