/* eslint-disable react/prop-types */
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

export default function NewFeatureFields({method, information, setInformation, handleUpdate, submit}) {
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    const newItems = () => {
        setInformation((prev) => ({
            ...prev,
            items: [
                ...prev?.items,
                {
                    id: Math.floor(Math.random() * 90000 + 10000),
                    name: "",
                }]
        }))
    };
    /**
     *
     * @param {any} value
     * @param {string} name
     * @param {number} itemId
     * @return void
     */
    const handleItemsChange = (value, name, itemId) => {
        setInformation((prev) => ({
            ...prev,
            items: prev?.items?.map((item) => {
                if (item.id === itemId) {
                    return {...item, [name]: value};
                } else {
                    return item;
                }
            })
        }));
    };

    return (
        <SoftBox py={3}>

            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام ویژگی
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام ویژگی"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                توضیحات
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="description" value={information.description} onChange={handleChange}
                                   type="text" placeholder="توضیحات"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                انتخاب چند آیتم؟
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_multi" value={String(information.is_multi)} onChange={handleChange}
                                fullWidth>
                            <MenuItem value={"1"}>بله</MenuItem>
                            <MenuItem value={"0"}>خیر</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ترتیب
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="order" value={information.order} onChange={handleChange}
                                   type="text" placeholder="ترتیب"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftButton style={{marginBottom: 10}} onClick={newItems} variant="gradient"
                                    color="success" size={"small"}>
                            آیتم جدید
                        </SoftButton>
                        {information?.items?.length > 0 && information?.items.map((item) => <section
                            key={item.id} style={{display: "flex", alignItems: "center", marginBottom: 5, flex: 1}}>
                            <SoftInput value={item.name}
                                       onChange={(event) => handleItemsChange(event.target.value, "name", item.id)}
                                       type="text" placeholder="نام"/>

                            <Icon color={"error"} onClick={() =>
                                setInformation((prev) => ({
                                    ...prev,
                                    items: prev?.items?.filter(prevPrice => prevPrice.id !== item.id)
                                }))}>close</Icon>
                        </section>)}
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        آپدیت
                    </SoftButton>}
            </SoftBox>
        </SoftBox>
    )
}
