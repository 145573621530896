/**
 *
 * @param {{cursor: unknown, name: (*|null)}} parameters
 * @return {string}
 */
const queryString = (parameters) => Object.entries(parameters)
    .filter(([_, value]) => value !== null)
    .map(([key, value]) => `${key}=${value}`).join('&');

export default queryString;
