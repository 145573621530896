import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getBusinessMenu} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Loading from "../../components/Loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MenuTable from "./MenuTable";

export default function Menu() {
    const {state} = useLocation();
    const {businessId} = state ?? [];
    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false);
    const [businessMenu, setBusinessMenu] = useState([])
    const [trigger, setTrigger] = useState(null);

    useEffect(() => {
        if (businessId !== undefined) {
            setLoading(true);
            const query = {
                with_products: 1
            }
            getBusinessMenu(businessId, queryString(query), trigger !== null, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setBusinessMenu(data);
                }
            })
        }
    }, [businessId, trigger]);

    useEffect(() => {
        if (searchParams.get("update"))
            setTrigger(Math.random());

    }, [searchParams.get("update")]);

    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <MenuTable data={businessMenu}/>
        </DashboardLayout>
    )
}
