import Loading from "../../components/Loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {getProductTag} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductTagTable from "./ProductTagTable";

export default function ProductTag() {
    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [productTag, setProductTag] = useState([]);
    const [trigger, setTrigger] = useState(null);

    useEffect(() => {
        setLoading(true);
        const query = {
            cursor: cursor === null ? null : cursor,
        }
        getProductTag(queryString(query), trigger !== null, (isOk, data) => {
            setLoading(false);
            if (!isOk) {
                return toast.error(data.message)
            } else {
                if (page !== 0) {
                    setProductTag((prev) => ([...prev, ...data.data]));
                } else {
                    setProductTag(data.data);
                }
                setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                if (data.meta.next_cursor !== null)
                    return setHasMore(true);

                else return setHasMore(false)
            }
        })
    }, [page, trigger]);

    useEffect(() => {
        if (searchParams.get("update")) {
            if (page === 0)
                setTrigger(Math.random());
            else
                setPage(0);

            setCursor(null);
        }
    }, [searchParams.get("update")]);

    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <InfiniteScroll dataLength={productTag.length} next={fetchMoreData} hasMore={hasMore}>
                <ProductTagTable data={productTag}/>
            </InfiniteScroll>
        </DashboardLayout>
    )
}
