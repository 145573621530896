import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import NewRatingFactorFields from "./NewRatingFactorFields";
import {getSingleRatingFactor, newRatingFactor, updateRatingFactor} from "../../api/routes";
import {toast} from "react-toastify";
import randomToken from "../../functions/randomToken";
import queryString from "../../functions/queryString";

export default function NewRatingFactor() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const ratingFactorId = searchParams.get("ratingFactorId")

    let schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
    });
    useEffect(() => {
        if (method === "update" && ratingFactorId) {
            setLoading(true);
            getSingleRatingFactor(ratingFactorId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {name, description,} = data;
                    setInformation({name, description,})
                }
            })
        }
    }, [ratingFactorId]);

    const submitRatingFactor = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();
                    for (let item in information) {
                        if (information[item])
                            formdata.append(item, information[item]);
                    }

                    setLoading(true);
                    newRatingFactor(formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(`/ratingFactor?update=${randomToken()}`);
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    const handleUpdateRatingFactor = () => {
        const data = {
            name:information.name,
            description:information.description
        }

        setLoading(true);
        updateRatingFactor(ratingFactorId, queryString(data), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/ratingFactor?update=${randomToken()}`);
            }
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <NewRatingFactorFields information={information} method={method} setInformation={setInformation}
                                   submit={submitRatingFactor} handleUpdate={handleUpdateRatingFactor}/>
        </DashboardLayout>
    )
}
