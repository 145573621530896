/* eslint-disable react/prop-types */
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import ModalUi from "../../../components/ModalUI";
import Loading from "../../../components/Loading";
import {getWalletTransactions} from "../../../api/routes";
import queryString from "../../../functions/queryString";
import InfiniteScroll from "react-infinite-scroll-component";
import WalletTransactionsTable from "./WalletTransactionsTable";


export default function WalletTransactionsModal({modalInfo, closeModal}) {
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [walletTransactions, setWalletTransactions] = useState([]);

    useEffect(() => {
        setLoading(true);
        const query = {
            cursor: cursor === null ? null : cursor,
            user_id:modalInfo.user_id
        }
        getWalletTransactions(queryString(query), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                if (page !== 0) {
                    setWalletTransactions((prev) => ([...prev, ...data.data]));
                } else {
                    setWalletTransactions(data.data);
                }
                setLoading(false);
                setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                if (data.meta.next_cursor !== null)
                    return setHasMore(true);

                else return setHasMore(false)
            }
        })
    }, [page]);
    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return (
        <ModalUi open={modalInfo.state} handleClose={closeModal}>
            <Loading loading={loading}/>
            <InfiniteScroll dataLength={walletTransactions.length} next={fetchMoreData} hasMore={hasMore}>
                <WalletTransactionsTable data={walletTransactions}/>
            </InfiniteScroll>
        </ModalUi>
    )
}
