/* eslint-disable react/prop-types */
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import {getBanner, getBusiness} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import LeafletMapContainer from "./LeafletMapContainer";

export default function Map() {
    const [business, setBusiness] = useState([]);
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState([]);
    const [location, setLocation] = useState({
        lat: 36.325320,
        lng: 59.508337
    });

    useEffect(() => {
        const query = {
            lat: location.lat,
            long: location.lng
        }
        setLoading(true);
        getBusiness(queryString(query), false, (isOk, data) => {
            setLoading(false);
            if (!isOk) {
                return toast.error(data.message)
            } else {
                setBusiness(data.data)
            }
        })
        const bannerQuery = {
            type: "Business",
            all: 1,
            lat: location.lat,
            long: location.lng,
            is_active: 1
        };
        setLoading(true);
        getBanner(queryString(bannerQuery), (isOk, data) => {
            setLoading(false);
            if (!isOk) {
                return toast.error(data.message)
            } else {
                setBanner(data.data);
            }
        });
    }, [location]);


    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <LeafletMapContainer business={business} banner={banner} location={location} setLocation={setLocation}/>
            <div style={{display:"flex"}}>
                <section style={{display:"flex"}}>
                    <div style={{backgroundColor:"#60FEA4",width:20,height:20,borderRadius:"100%"}}/>
                    <p style={{fontSize:'14px',marginRight:2}}>کسب و کار</p>
                </section>
                <section style={{display:"flex",marginRight:10}}>
                    <div style={{backgroundColor:"#FF624A",width:20,height:20,borderRadius:"100%"}}/>
                    <p style={{fontSize:'14px',marginRight:2}}>بنر</p>
                </section>
            </div>
        </DashboardLayout>
    )
}
