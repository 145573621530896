/* eslint-disable react/prop-types */
import SoftBox from "../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../../components/SoftTypography";
import SoftInput from "../../../components/SoftInput";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SoftButton from "../../../components/SoftButton";
import * as React from "react";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom";
import {newQrcode} from "../../../api/routes";
import queryString from "../../../functions/queryString";
import {useState} from "react";
import Loading from "../../../components/Loading";
import LeafletMap from "../../../components/LeafletMap";
import GenerateQR from "../../../components/GenerateQR";

export default function ModalNewQRFields({setQrInfo}) {
    const [searchParams] = useSearchParams();
    const journeyId = searchParams.get("journeyId")
    const [loading, setLoading] = useState(false);
    const [submittedQR, setSubmittedQR] = useState();
    const [information, setInformation] = useState({});
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    let schema = yup.object().shape({
        name: yup.string().required(),
        total: yup.number().required(),
        is_active: yup.string().required(),
        points_amount: yup.number(),
        wallet_amount: yup.number(),
        order: yup.number(),
        position: yup.string(),
        nfc_id: yup.string(),
    });

    const submitQrcode = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let qrData = {};
                    const {position, ...restInformation} = information;
                    for (let item in restInformation) {
                        if (restInformation[item]) {
                            qrData[item] = restInformation[item];
                        }
                    }
                    setLoading(true)
                    if (journeyId)
                        qrData["journey_id"] = journeyId;
                    if (position && position.lat) {
                        qrData["lat"] = position.lat;
                        qrData["long"] = position.lng;
                    }

                    newQrcode(queryString(qrData), (isOk, data) => {
                        setLoading(false)
                        if (!isOk) {
                            return toast.error(data.message);
                        } else {
                            toast.success(`QR اضافه شد!`);
                            setQrInfo(prev => [...prev, {id: Math.random().toString(16).slice(2), ...information}])
                            setInformation({})
                            setSubmittedQR(data)

                        }
                    });
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }

    return (
        <>
            <Loading loading={loading}/>
            {!submittedQR ? <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تعداد کل
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="total" value={information.total} onChange={handleChange}
                                   type="text" placeholder="تعداد کل"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مقدار امتیاز
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="points_amount" value={information.points_amount} onChange={handleChange}
                                   type="text" placeholder="مقدار امتیاز"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مقدار کیف پول
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="wallet_amount" value={information.wallet_amount} onChange={handleChange}
                                   type="text" placeholder="مقدار کیف پول"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ترتیب
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="order" value={information.order} onChange={handleChange}
                                   type="text" placeholder="ترتیب"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                NFC id
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="nfc_id" value={information.nfc_id} onChange={handleChange}
                                   type="text" placeholder="NFC id"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                وضعیت نمایش
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_active" value={String(information.is_active)} onChange={handleChange}
                                fullWidth
                                placeholder='Is active'>
                            <MenuItem value={"1"}>فعال</MenuItem>
                            <MenuItem value={"0"}>غیرفعال</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={12}>
                        <LeafletMap style={{width: "100%", height: 300}} selectedPosition={information.position}
                                    setSelectedPosition={(location) => setInformation((prev) => ({
                                 ...prev,
                                 position: location
                             }))}
                                    zoom={12}/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftButton onClick={submitQrcode} variant="gradient" color="info">
                            ثبت
                        </SoftButton>
                    </Grid>
                </Grid>
            </SoftBox> : <GenerateQR QRInfo={submittedQR}/> }
        </>
    )
}
