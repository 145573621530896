/* eslint-disable react/prop-types */
import {useEffect, useState} from "react";
import {getArea, getCity} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import LeafletMap from "../../components/LeafletMap";
import * as React from "react";
import SoftButton from "../../components/SoftButton";

export default function NewBannerFields({method, information, setInformation, handleUpdate, submit}) {
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };

    useEffect(() => {
        const query = {
            country_id: 1
        }
        setLoading(true);
        getCity(queryString(query), true, (isOk, data) => {
            setLoading(false);
            if (!isOk) {
                return toast.error(data.message)
            } else setCity(data);

        });
    }, []);

    useEffect(() => {
        if (information?.city_id !== undefined) {
            const query = {
                city_id: information?.city_id,
            }
            setLoading(true);
            getArea(queryString(query), false, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setArea(data)
                }
            })
        }
    }, [information?.city_id]);

    const handleImageChange = (event) => {
        const {name, files} = event.target;
        setInformation({...information, [name]: files})
    };

    return (
        <SoftBox py={3}>
            <Loading loading={loading}/>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                توضیحات
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="description" value={information.description} onChange={handleChange}
                                   type="text" placeholder="توضیحات"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شهر
                            </SoftTypography>
                        </SoftBox>
                        <Select name="city_id" value={String(information.city_id)} onChange={handleChange}
                                fullWidth
                                placeholder='شهر'>
                            {city.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                منطقه
                            </SoftTypography>
                        </SoftBox>
                        <Select name="area_id" value={String(information.area_id)} onChange={handleChange}
                                fullWidth
                                placeholder='منطقه'>
                            {area.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نوع
                            </SoftTypography>
                        </SoftBox>
                        <Select name="type" value={String(information.type)} onChange={handleChange}
                                fullWidth>
                            <MenuItem value={"Business"}>Business</MenuItem>
                            <MenuItem value={"Product"}>Product</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ترتیب
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="order" value={information.order} onChange={handleChange}
                                   type="text" placeholder="ترتیب"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                وضعیت نمایش
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_active" value={String(information.is_active)} onChange={handleChange}
                                fullWidth>
                            <MenuItem value={"1"}>فعال</MenuItem>
                            <MenuItem value={"0"}>غیرفعال</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تصویر
                            </SoftTypography>

                        </SoftBox>
                        <input name="image" onChange={handleImageChange} type="file" style={{
                            background: 'white',
                            padding: '8px 12px',
                            width: '100%',
                            border: '0.0625rem solid #d2d6da',
                            borderRadius: '0.5rem',
                            color: '#495057'
                        }} placeholder="Images" accept=".jpg,.jpeg,.png"/>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={12}>
                        <LeafletMap style={{width: "100%", height: 300}} selectedPosition={information.position}
                                    setSelectedPosition={(location) => setInformation((prev) => ({
                                        ...prev,
                                        position: location
                                    }))}
                                    zoom={12}/>
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        آپدیت
                    </SoftButton>}
            </SoftBox>
        </SoftBox>
    )
}
