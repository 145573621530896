/* eslint-disable react/prop-types */
import {MapContainer, Marker, TileLayer, useMapEvents, Popup} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import "leaflet-defaulticon-compatibility"
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css"
import icon2x from 'leaflet/dist/images/marker-icon-2x.png';
import {Icon} from 'leaflet'

const myLocationIcon = new Icon({
    iconUrl: icon2x,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
})
const businessIcon = new Icon({
    iconSize: [36, 38],
    iconAnchor: [12, 41],
    iconUrl: 'https://img.icons8.com/pulsar-gradient/36/marker.png'
})
const bannerIcon = new Icon({
    iconSize: [36, 38],
    iconAnchor: [12, 41],
    iconUrl: 'https://img.icons8.com/skeuomorphism/36/experimental-marker-skeuomorphism.png'
})

export default function LeafletMapContainer({business,banner,location,setLocation}){
    const LocationFinderDummy = () => {
        useMapEvents({
            click(e) {
                setLocation(e.latlng);
            },
        });
        return null;
    };
    return(
        <MapContainer zoomControl style={{width: "100%", height: '90vh'}}
                      center={{lat: 36.297610076585485, lng: 59.60661556379091}}
                      zoom={12}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {Object(location).hasOwnProperty("lat") && <Marker position={location} icon={myLocationIcon}>
                <Popup><p style={{fontFamily: "IRANSansX"}}>مکان انتخاب شده</p></Popup>
            </Marker>}
            {business?.map(item => <Marker key={item.id} position={{lat: item.lat, lng: item.long}}
                                           icon={businessIcon}>
                <Popup><p style={{fontFamily: "IRANSansX"}}>{item.name}</p></Popup>
            </Marker>)}
            {banner?.map(item => <Marker key={item.id} position={{lat: item.lat, lng: item.long}}
                                           icon={bannerIcon}>
                <Popup><p style={{fontFamily: "IRANSansX"}}>{item.name}</p></Popup>
            </Marker>)}
            <LocationFinderDummy/>
        </MapContainer>
    )
}
