import SoftBadge from "../../../components/SoftBadge";

/**
 *
 * @param {function(status:number,businessID:number)} handleUpdateBusinessStatus
 * @param {number} businessStatus
 * @param {number} businessID
 * @return {JSX.Element}
 */
const handleBusinessStatusView = (handleUpdateBusinessStatus,businessStatus, businessID) => {
    switch (businessStatus) {
        case 1:
            return <section style={{display: "flex"}}>
                <SoftBadge variant="contained"
                           onClick={()=> handleUpdateBusinessStatus(3,businessID)}
                           badgeContent="تایید اطلاعات"
                           color="success" size="xs"/>
                <SoftBadge variant="contained"
                           onClick={()=> handleUpdateBusinessStatus(2,businessID)}
                           badgeContent="رد اطلاعات"
                           color="error" size="xs"/>
            </section>
    }
};
export default handleBusinessStatusView
