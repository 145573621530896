/* eslint-disable react/prop-types */
import QrCodeIcon from "@mui/icons-material/QrCode";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteJourney } from "../../api/routes";
import Loading from "../../components/Loading";
import SoftBadge from "../../components/SoftBadge";
import ellipsis from "../../functions/ellipsis";
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import useModal from "../../hooks/useModal";
import MoreInfoModal from "./MoreInfoModal";

function createData(id, name, description, isOrderBase, needComplete, progressPercent, isActive, category, qrCodeCount, operation) {
  return {
    id,
    name,
    description,
    isOrderBase,
    needComplete,
    progressPercent,
    isActive,
    category,
    qrCodeCount,
    operation,
  };
}

function Row(props) {
  const { row } = props;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className="tablecell" align="left" style={{ ...fixTableCellWidth("160px"), cursor: "pointer" }}><QrCodeIcon
          onClick={() => navigate(`/journey-qr-codes-preview`, { state: row.id })} />{row.name}</TableCell>
        <TableCell className="tablecell" align="left" title={row.description}
          style={fixTableCellWidth("160px")}>{ellipsis(row.description, 20)}</TableCell>
        <TableCell className="tablecell" align="left" title={row.category}
          style={fixTableCellWidth("120px")}>{row.category}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("110px")}>{row.isOrderBase ? "بله" : "خیر"}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("100px")}>{row.needComplete ? "بله" : "خیر"}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("110px")}>%{row.progressPercent ? parseFloat(row.progressPercent) * 100 : 0}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("90px")}>{row.qrCodeCount}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("100px")}>{row.isActive ? "فعال" : "غیرفعال"}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("250px")}>{row.operation}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function JourneyTable({ data }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [modals, updateModal, closeModal] = useModal({
    moreInfo: { state: false },
  });
  const closeMoreInfoModal = () => closeModal("moreInfo");

  const handleDeleteJourney = (journeyId) => {
    let answer = window.confirm("آیا از حذف اطمینان دارید؟");
    if (answer) {
      setLoading(true);
      deleteJourney(journeyId, (isOk, data) => {
        if (!isOk) {
          setLoading(false);
          return toast.error(data.message);
        } else {
          setLoading(false);
          toast.success("Your request has been submitted");
          window.location.reload();
        }
      });
    }
  };
  const rows = data.map(item => createData(item.id, item.name, item?.description, item?.is_order_based,
    item?.need_complete, item?.ppercent, item.is_active, item.category, item.q_r_codes_count,
    <section style={{ display: "flex", cursor: "pointer", flexWrap: "wrap", gap: 2 }}>
      <SoftBadge variant="contained"
        badgeContent="اطلاعات بیشتر"
        onClick={() => updateModal("moreInfo", { state: true, info: item })}
        color="info" size="xs" />
      <SoftBadge variant="contained"
        badgeContent="کیوآر جدید"
        onClick={() => navigate(`/new-journey?modal=true&journeyId=${item.id}`)}
        color="primary" size="xs" />
      <SoftBadge variant="contained"
        badgeContent="کل کیوآرها"
        onClick={() => navigate(`/qrcode?journey_id=${item.id}`)}
        color="success" size="xs" />
      <SoftBadge variant="contained"
        badgeContent="ویرایش"
        onClick={() => navigate(`/new-journey?method=update&journeyId=${item.id}`)}
        color="secondary" size="xs" />
      <SoftBadge variant="contained"
        badgeContent="حذف"
        onClick={() => handleDeleteJourney(item.id)}
        color="error" size="xs" />
    </section>,
  ));

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <Loading loading={loading} />
          <TableHead>
            <SoftBadge onClick={() => navigate("/new-journey")} style={{ cursor: "pointer" }}
              variant="contained"
              badgeContent="چالش جدید"
              color="success" size="sm" />
            <TableRow>
              <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("130px")}>توضیحات</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("120px")}>دسته بندی</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("120px")}>براساس ترتیب</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("100px")}>نیاز به
                اتمام</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("120px")}>درصد پیشرفت</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("100px")}>تعداد qr</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("120px")}>وضعیت چالش</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("230px")}>عملیات ها</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ display: "block" }}>
            {rows.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modals.moreInfo.state && <MoreInfoModal modalInfo={modals.moreInfo} closeModal={closeMoreInfoModal} />}
    </>
  );
}
