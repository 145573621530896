import Loading from "../../../components/Loading";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import DiscountCardDesignTable from "./DiscountCardDesignTable";
import { getDiscountCardDesign } from "../../../api/routes";
import { toast } from "react-toastify";

export default function DiscountCardDesign() {
  const [loading, setLoading] = useState(false);
  const [discountCardDesign, setDiscountCardDesign] = useState([]);
  useEffect(() => {
    setLoading(true);
    getDiscountCardDesign((isOk, data) => {
      if (!isOk) {
        setLoading(false);
        return toast.error(data.message)
      } else {
        setLoading(false);
        setDiscountCardDesign(data)
      }
    })
  }, []);

  return(
    <DashboardLayout>
      <Loading loading={loading}/>
      <DashboardNavbar/>
      <DiscountCardDesignTable data={discountCardDesign} setDiscountCardDesign={setDiscountCardDesign}/>
    </DashboardLayout>
  )
}
