import {toast} from "react-toastify";
import {deleteProductCategory} from "../../api/routes";

/**
 *
 * @param {number} productCategoryId
 * @param {function(value:boolean)} setLoading
 * @return void
 */
const handleDeleteProductCategory = (productCategoryId,setLoading)=>{
    let answer = window.confirm("Are you sure about deleting category?");
    if (answer) {
        setLoading(true);
        deleteProductCategory(productCategoryId, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                window.location.reload()
            }
        })
    } else {
    }
}
export default handleDeleteProductCategory
