import { useEffect, useState } from "react";
import { getMug } from "../../api/routes";
import queryString from "../../functions/queryString";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const mugWithData = (Component) => {
  return () => {
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [mug, setMug] = useState([]);
    const [sponsor, setSponsor] = useState(null);
    const [trigger, setTrigger] = useState(0);
    const handleChange = (event) => {
      setSponsor(event.target.value);
    };

    useEffect(() => {
      setLoading(true);
      const query = {
        cursor: cursor === null ? null : cursor,
        sponsor: sponsor || null,
      };
      getMug(queryString(query), (isOk, data) => {
        if (!isOk) {
          setLoading(false);
          return toast.error(data.message);
        } else {
          if (page !== 0) {
            setMug((prev) => ([...prev, ...data.data]));
          } else {
            setMug(data.data);
          }
          setLoading(false);
          if (sponsor === null) {
            setCursor(data?.meta?.next_cursor === null ? "" : data?.meta?.next_cursor);
            if (data?.meta?.next_cursor !== null)
              return setHasMore(true);

            else return setHasMore(false);
          } else setHasMore(false);
        }
      });

    }, [page, trigger]);

    const fetchMoreData = () => {
      if (loading) return;
      if (hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    };
    const onRefresh = () => {
      setCursor(null);
      if (page === 0)
        setTrigger(Math.random());
      else
        setPage(0);
    };
    const handleDeleteFilter = ()=>{
      setSponsor(null);
      onRefresh()
    }
    return (
      <>
        <Loading loading={loading} />
        <InfiniteScroll dataLength={mug.length} next={fetchMoreData} hasMore={hasMore}>
          <Component data={mug} sponsor={sponsor} handleDeleteFilter={handleDeleteFilter} onRefresh={onRefresh} handleChange={handleChange} />
        </InfiniteScroll>
      </>
    );
  };
};
export default mugWithData;
