/* eslint-disable react/prop-types */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { deleteDiscountCardDesign } from 'api/routes';
import Loading from 'components/Loading';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import SoftBadge from "../../../components/SoftBadge";
import fixTableCellWidth from "../../../functions/fixTableCellWidth";
import useModal from "../../../hooks/useModal";
import NewDesignModal from "./NewDesignModal";

function createData(id, color, background, operation) {
  return {
    id,
    color,
    background,
    operation
  };
}

function Row(props) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell className="tablecell" align="left" style={fixTableCellWidth("150px")}>{row.color}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("160px")}>{row.background && <img src={row.background} alt="background" style={{ width: 100, borderRadius: 5 }} />}</TableCell>
        <TableCell className="tablecell" align="left"
          style={fixTableCellWidth("150px")}>{row.operation}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function DiscountCardDesignTable({ data, setDiscountCardDesign }) {
  const [loading, setLoading] = useState(false);
  const [modals, updateModal, closeModal] = useModal({
    design: { state: false },
  })
  const closeDesignModal = () => closeModal('design');

  const handleDeleteDiscountDesign = (discountCardDesignId) => {
    let answer = window.confirm("آیا از حذف اطمینان دارید؟");
    if (answer) {
      setLoading(true);
      deleteDiscountCardDesign(discountCardDesignId, (isOk, data) => {
        if (!isOk) {
          setLoading(false);
          return toast.error(data.message);
        } else {
          setLoading(false);
          toast.success("Your request has been submitted");
          setDiscountCardDesign(prev => prev.filter(item => item.id !== discountCardDesignId))
        }
      });
    }
  };
  const rows = data.map(item => createData(item.id, item.color, item.background,
    <section style={{ display: "flex", cursor: "pointer", flexWrap: "wrap", gap: 2 }}>
      <SoftBadge variant="contained"
        badgeContent="ویرایش"
        onClick={() => updateModal("design", { state: true, data: item, isUpdate: true })}
        color="secondary" size="xs" />
      <SoftBadge variant="contained"
        badgeContent="حذف"
        onClick={() => handleDeleteDiscountDesign(item.id)}
        color="error" size="xs" />
    </section>,
  ))

  return (
    <>
      <TableContainer component={Paper}>
        <Loading loading={loading} />
        <Table>
          <TableHead>
            <SoftBadge variant="contained"
              badgeContent="طرح جدید"
              onClick={() => updateModal("design", { state: true })}
              style={{ cursor: "pointer" }}
              color="success" size="xs" />
            <TableRow>
              <TableCell className="tablecell" style={fixTableCellWidth("150px")}>رنگ</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("150px")}>پس زمینه</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("130px")}>عملیات ها</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ display: 'block' }}>
            {rows.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modals.design.state && <NewDesignModal modalInfo={modals.design} closeModal={closeDesignModal} setDiscountCardDesign={setDiscountCardDesign} />}
    </>
  );
}
