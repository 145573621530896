import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import NewMenuFields from "./NewMenuFields";
import {getSingleMenuSection, newBusinessMenu, updateSingleMenuSection} from "../../api/routes";
import {toast} from "react-toastify";

export default function NewMenu() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const method = searchParams.get("method")
    const businessId = searchParams.get("businessId")
    const menuId = searchParams.get("menuId")

    let schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        order: yup.number(),
        is_active: yup.boolean().required(),
    });

    useEffect(() => {
        if (method === "update" && menuId) {
            setLoading(true);
            getSingleMenuSection(menuId, true, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setInformation({
                        order: data.order,
                        name: data?.name,
                        description: data?.description ?? "",
                        is_active: +data?.is_active
                    })
                }
            })
        }
    }, [menuId]);

    const submitNewMenu = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();

                    formdata.append("name", information?.name);
                    formdata.append("is_active", information?.is_active);

                    if (information?.order)
                        formdata.append("order", information?.order);

                    if (information?.description)
                        formdata.append("description", information?.description);


                    setLoading(true);
                    newBusinessMenu(businessId, formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(-1);
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })

    }

    const handleUpdateMenu = () => {
        let formdata = new FormData();

        formdata.append("name", information?.name);
        formdata.append("is_active", information?.is_active);

        if (information?.order)
            formdata.append("order", information?.order);

        if (information?.description)
            formdata.append("description", information?.description);


        setLoading(true);
        updateSingleMenuSection(menuId, formdata, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(-1);
            }
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <NewMenuFields method={method} information={information} setInformation={setInformation}
                           submit={submitNewMenu} handleUpdate={handleUpdateMenu}/>
        </DashboardLayout>
    )
}
