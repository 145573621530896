/* eslint-disable react/prop-types */
import ModalUi from 'components/ModalUI'
import React from 'react';
import ellipsis from "../../../functions/ellipsis";
import Grid from "@mui/material/Grid";
import SoftBadge from "../../../components/SoftBadge";
import {useNavigate} from "react-router-dom";

export default function SubCategoryModal({
                                             subCategory,
                                             updatePath,
                                             closeSubCategory,
                                             handleDeleteCategory,
                                             setLoading
                                         }) {
    const navigate = useNavigate();
    return (
        <>
            <ModalUi open={subCategory.state} handleClose={closeSubCategory}>
                <div style={{minWidth: 500, margin: '2rem'}}>
                    <Grid container gap={2} mb={2} style={{borderBottom: "1px solid #ccc", paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <p style={{fontSize: 16, fontWeight: "bold"}}>نام</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p style={{fontSize: 16, fontWeight: "bold"}}>توضیحات</p>
                        </Grid>
                        <Grid item xs={3}>
                            <p style={{fontSize: 16, fontWeight: "bold"}}>عملیات</p>
                        </Grid>
                    </Grid>
                    {subCategory.data.map((category, index) => <Grid key={index} container gap={2} mb={2} style={{
                        fontSize: 16,
                        borderBottom: subCategory.data.length - 1 !== index && "1px solid #e7e7e7",
                        paddingBottom: 10
                    }}>
                        <Grid item xs={4}>
                            <p>{category.name}</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p title={category.description}>{ellipsis(category.description, 50)}</p>
                        </Grid>
                        <Grid item xs={3} style={{cursor: "pointer"}}>
                            <SoftBadge variant="contained" badgeContent="آپدیت" color="secondary" size="xs"
                                       style={{marginLeft: 5}}
                                       container
                                       onClick={() => navigate(`/${updatePath}?method=update&categoryId=${category.id}`)}/>
                            <SoftBadge variant="contained" onClick={() => handleDeleteCategory(category.id, setLoading)}
                                       badgeContent="حذف" color="error" size="xs" container/>
                        </Grid>
                    </Grid>)}
                </div>
            </ModalUi>
        </>
    )
}
