/* eslint-disable react/prop-types */
import ModalUi from "../../components/ModalUI";
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {useEffect, useState} from "react";
import {Select, Stack, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {getDiscountCardDesign, getSingleDiscountCardIssue, getUpdateDiscountCardIssue} from "../../api/routes";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";
import SoftButton from "../../components/SoftButton";
import queryString from "../../functions/queryString";
import randomToken from "../../functions/randomToken";
import {useNavigate} from "react-router-dom";

export default function UpdateModal({updateModal, closeUpdateModal}) {
    const [information, setInformation] = useState({});
    const [loading, setLoading] = useState(false);
    const [designCardDesign, setDesignCardDesign] = useState([]);
    const navigate = useNavigate()
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    const dateHandleChange = (value, name) => {
        setInformation({
            ...information,
            [name]: `${value.$d.getUTCFullYear()}-${String(value.$d.getUTCMonth() + 1).padStart(2, "0")}-${String(value.$d.getUTCDate()+ 1).padStart(2, "0")}`
        });
    };
    useEffect(() => {
        if (updateModal.discountCardIssueId) {
            setLoading(true);
            getSingleDiscountCardIssue(updateModal.discountCardIssueId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setInformation({
                        card_price: data.card_price,
                        is_ready: String(+data.is_ready),
                        expires_at: data.expires_at.slice(0, 10),
                        available_at: data.available_at.slice(0, 10),
                        points: data.points,
                        discount_card_design_id: data.discount_card_design_id,
                    })
                }
            })
        }
    }, [updateModal.discountCardIssueId]);

    useEffect(() => {
        setLoading(true);
        getDiscountCardDesign((isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                setDesignCardDesign(data)
            }
        })
    }, []);

    const submit = () => {
        const query = {
            points: information.points ?? null,
            expires_at: information.expires_at ?? null,
            card_price: information.card_price ?? null,
            is_ready: information.is_ready ?? null,
            discount_card_design_id: information.discount_card_design_id ?? null,
            available_at: information.available_at ?? null
        }
        setLoading(true);
        getUpdateDiscountCardIssue(queryString(query), updateModal.discountCardIssueId, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                closeUpdateModal()
                toast.success("Your request has been submitted")
                navigate(`/discountCardIssue?update=${randomToken()}`);
            }
        })
    }
    return (
        <ModalUi open={updateModal.state} handleClose={closeUpdateModal}>
            <Loading loading={loading}/>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                قیمت کارت
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="card_price" value={information.card_price} onChange={handleChange}
                                   type="text" placeholder="قیمت کارت"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Is Ready
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_ready" value={String(information.is_ready)} onChange={handleChange}
                                fullWidth
                                placeholder='Is Ready'>
                            <MenuItem value={"1"}>بله</MenuItem>
                            <MenuItem value={"0"}>خیر</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                امتیاز
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="points" value={information.points} onChange={handleChange}
                                   type="text" placeholder="امتیاز"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تاریخ انقضا
                            </SoftTypography>
                        </SoftBox>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={information.expires_at}
                                    onChange={(newValue) => dateHandleChange(newValue, "expires_at")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تاریخ در دسترس
                            </SoftTypography>
                        </SoftBox>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={information.available_at}
                                    onChange={(newValue) => dateHandleChange(newValue, "available_at")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                طرح کارت
                            </SoftTypography>
                        </SoftBox>
                        <Select name="discount_card_design_id" value={String(information.discount_card_design_id)}
                                onChange={handleChange}
                                fullWidth
                        >
                            {designCardDesign.map((item, index) => <MenuItem key={item.id}
                                                                             value={item.id}>طرح {index + 1}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        {designCardDesign.find(card => card.id === information.discount_card_design_id)?.color ? <div
                                style={{
                                    height: "100%",
                                    borderRadius: 10,
                                    background: designCardDesign.find(card => card.id === information.discount_card_design_id)?.color
                                }}/>
                            : <img style={{height: "78px", width: "100%", borderRadius: 10}}
                                   src={designCardDesign.find(card => card.id === information.discount_card_design_id)?.background}
                                   alt={"design"}/>}
                    </Grid>
                    <SoftButton onClick={submit} style={{marginTop: 20, marginRight: 20}} variant="gradient"
                                color="info">
                        ثبت
                    </SoftButton>
                </Grid>
            </SoftBox>
        </ModalUi>
    )
}
