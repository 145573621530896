/* eslint-disable react/prop-types */
import ModalUi from "../../../components/ModalUI";
import {useState} from "react";
import SoftBadge from "../../../components/SoftBadge";
import * as React from "react";
import Grid from "@mui/material/Grid";
import ModalNewQRFields from "./ModalNewQRFields";

export default function QrModal({modalInfo, closeModal}) {
    const [step, setStep] = useState(0)
    const [qrInfo, setQrInfo] = useState([]);

    return (
        <ModalUi open={modalInfo.state} handleClose={closeModal} style={{minWidth: "600px"}}>
            {step === 0 && <>
                <SoftBadge variant="contained"
                           badgeContent="qr جدید"
                           style={{cursor: "pointer",marginBottom:16}}
                           onClick={() => setStep(1)}
                           color="success" size="lg" />
                {qrInfo.map((item, index) => <Grid key={index} container gap={2} mb={2} style={{
                    fontSize: 16,
                    borderBottom: qrInfo.length - 1 !== index && "1px solid #e7e7e7",
                    paddingBottom: 10
                }}>
                    <Grid item xs={4}>
                        <p style={{ fontWeight: "bold",fontSize:14 }}>نام: <span
                            style={{ fontWeight: "normal" }}>{item.name}</span>
                        </p>
                    </Grid>
                    <Grid item xs={4}>
                        <p title={item.total}
                           style={{ fontWeight: "bold", marginLeft: 10,fontSize:14 }}>تعداد کل: <span
                            style={{ fontWeight: "normal" }}>{item.total}</span></p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ fontWeight: "bold", marginLeft: 10,fontSize:14 }}>وضعیت نمایش: <span
                            style={{ fontWeight: "normal" }}>{parseInt(item.is_active) ? "فعال":"غیرفعال"}</span></p>
                    </Grid>
                </Grid>)}
            </>}
            {step === 1 && <>
                <SoftBadge variant="contained"
                           badgeContent="بازگشت"
                           style={{cursor: "pointer"}}
                           onClick={() => setStep(0)}
                           size="lg"/>
                <ModalNewQRFields setQrInfo={setQrInfo} />
            </>}
        </ModalUi>
    )
}
