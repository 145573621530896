/* eslint-disable react/prop-types */
import ModalUi from "../../components/ModalUI";
import GenerateQR from "../../components/GenerateQR";
import * as React from "react";

export default function QRInfoModal({modalInfo, closeModal,downloadFieldName="name"}) {

    return(
        <ModalUi open={modalInfo.state} handleClose={closeModal} style={{minWidth: "600px"}}>
            <GenerateQR QRInfo={modalInfo.QRInfo} downloadFieldName={downloadFieldName}/>
        </ModalUi>
    )
}
