import axios from "axios";
import cachios from "cachios";
import {onRequest} from "./createAxiosInstance";

const cacheAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Accept-Language': 'fa',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

cacheAxiosInstance.interceptors.request.use(onRequest, null);

export const cachiosInstance = cachios.create(cacheAxiosInstance);
