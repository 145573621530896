import React from "react";
import BusinessCategory from "./layouts/business/businessCategory";
import Home from "./layouts/home";
import NewBusinessCategory from "./layouts/business/businessCategory/NewBusinessCategory";
import Menu from "./layouts/menu";
import NewMenu from "./layouts/menu/NewMenu";
import NewProductCategory from "./layouts/productCategory/NewProductCategory";
import NewCountry from "./layouts/country/NewCountry";
import NewBusiness from "./layouts/business/NewBusiness";
import NewJourney from "./layouts/journey/NewJourney";
import NewQRCode from "./layouts/qrcode/NewQRCode";
import NewPenalty from "./layouts/penalty/NewPenalty";
import NewBanner from "./layouts/banner/NewBanner";
import NewRatingFactor from "./layouts/ratingFactor/NewRatingFactor";
import NewProductTag from "./layouts/productTag/NewProductTag";
import NewFeature from "./layouts/feature/NewFeature";
import MugsQRCodesPreview from "./layouts/mug/MugsQRCodesPreview";
import JourneyQRCodesPreview from "./layouts/journey/JourneyQRCodesPreview";
import DiscountCardDesign from "./layouts/discountCard/discountCardDesign";

const partialRoutes = [
    {
        path: "/",
        element: <Home/>
    },
    {
        path:"/business-category",
        element:<BusinessCategory/>
    },
    {
        path:"/new-business-category",
        element:<NewBusinessCategory/>
    },
    {
        path:"/menu",
        element: <Menu/>
    },
    {
        path:"/new-menu",
        element: <NewMenu/>
    },
    {
        path:"/new-product-category",
        element:<NewProductCategory/>
    },
    {
        path:"/new-country",
        element:<NewCountry/>
    },
    {
        path:"/mugs-qr-codes-preview",
        element:<MugsQRCodesPreview/>
    },
    {
        path:"/journey-qr-codes-preview",
        element:<JourneyQRCodesPreview/>
    },
    {
        path:"/new-qr-code",
        element:<NewQRCode/>
    },
    {
        path:"/discount-card-design",
        element:<DiscountCardDesign/>
    },
    {
        path:"/new-business",
        element:<NewBusiness/>
    },
    {
        path:"/new-journey",
        element:<NewJourney/>
    },
    {
        path:"/new-penalty",
        element:<NewPenalty/>
    },
    {
        path:"/new-banner",
        element:<NewBanner/>
    },
    {
        path:"/new-rating-factor",
        element:<NewRatingFactor/>
    },
    {
        path:"/new-product-tag",
        element:<NewProductTag/>
    },
    {
        path:"/new-feature",
        element:<NewFeature/>
    },
];

export default partialRoutes;
