import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {
    newProductTag,
    updateProductTag,
} from "../../api/routes";
import {toast} from "react-toastify";
import randomToken from "../../functions/randomToken";
import queryString from "../../functions/queryString";
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default function NewProductTag() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const productTagId = searchParams.get("productTagId")
    const productTagName = searchParams.get("productTagName")

    let schema = yup.object().shape({
        name: yup.string().required(),
    });
    useEffect(() => {
        if (method === "update" && productTagId &&productTagName) {
            setInformation({name: productTagName})
        }
    }, [productTagId]);

    const submitRatingFactor = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();
                    for (let item in information) {
                        if (information[item])
                            formdata.append(item, information[item]);
                    }

                    setLoading(true);
                    newProductTag(formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(`/productTag?update=${randomToken()}`);
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    const handleUpdateRatingFactor = () => {
        const data = {
            name: information.name,
        }

        setLoading(true);
        updateProductTag(productTagId, queryString(data), (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/productTag?update=${randomToken()}`);
            }
        })
    }
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <SoftBox py={3}>
                <SoftBox component="form" role="form">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    نام
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput name="name" value={information.name} onChange={handleChange}
                                       type="text" placeholder="نام"/>
                        </Grid>
                    </Grid>
                    {method !== "update" ?
                        <SoftButton onClick={submitRatingFactor} style={{marginTop: 40}} variant="gradient"
                                    color="info">
                            ثبت
                        </SoftButton> :
                        <SoftButton onClick={handleUpdateRatingFactor} style={{marginTop: 40}} variant="gradient"
                                    color="info">
                            آپدیت
                        </SoftButton>}
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    )
}
