import {useState} from "react";

/**
 * Custom hook for manage modal
 * @param {Object} initialState
 * @return {[modals:Object,updateModal:function(modalName:string, newValue:any),closeModal:function(modalName:string)]}
 */
export default function useModal(initialState){
    const [modals, setModals] = useState(initialState);
    // close functions for modals
    const closeModal = (modalName) => {
        setModals((prevState) => ({
            ...prevState,
            [modalName]: {...prevState[modalName], state: false},
        }));
    };

    const updateModal = (modalName, newValue) => {
        setModals((prevState) => ({
            ...prevState,
            [modalName]: {...newValue},
        }));
    }
    return [modals,updateModal,closeModal]
}
