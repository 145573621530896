/* eslint-disable react/prop-types */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import SoftBadge from "../../components/SoftBadge";
import useModal from "../../hooks/useModal";
import NewMugModal from "./NewMugModal";
import QRInfoModal from "../qrcode/QRInfoModal";
import MugSponsors from "./MugSponsors";
import SoftButton from "../../components/SoftButton";
import { useNavigate } from "react-router-dom";

function createData(id, sponsor, additionalPoints, customerId, uuid, createdAt, operation) {
  return {
    id,
    sponsor,
    additionalPoints,
    customerId,
    uuid,
    createdAt,
    operation,
  };
}

function Row(props) {
  const { row } = props;
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className="tablecell" align="left"
                   style={{ ...fixTableCellWidth("160px"), paddingRight: 30 }}>{row.sponsor}</TableCell>
        <TableCell className="tablecell" align="left" title={row.additionalPoints}
                   style={fixTableCellWidth("140px")}>{row.additionalPoints}</TableCell>
        <TableCell className="tablecell" align="left"
                   style={fixTableCellWidth("130px")}>{row.customerId ? "بله" : "خیر"}</TableCell>
        <TableCell className="tablecell" align="left" title={new Date(row.createdAt).toLocaleString("fa-IR")}
                   style={fixTableCellWidth("130px")}>{new Date(row.createdAt).toLocaleString("fa-IR")}</TableCell>
        <TableCell className="tablecell" align="left"
                   style={fixTableCellWidth("250px")}>{row.operation}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MugTable({ data, sponsor, handleDeleteFilter, onRefresh, handleChange }) {
  const [modals, updateModal, closeModal] = useModal({
    newMug: { state: false },
    qrcode: { state: false },
  });
  const navigate = useNavigate()
  const closeNewMugModal = () => closeModal("newMug");
  const closeQRCode = () => closeModal("qrcode");

  const rows = data.map(item => createData(item.id, item.sponsor, item?.additional_points, item?.customer_id, item.uuid, item.created_at,
    <section style={{ display: "flex", cursor: "pointer" }}>
      <SoftBadge variant="contained"
                 badgeContent="QR"
                 onClick={() => updateModal("qrcode", { state: true, QRInfo: item })}
                 color="primary" size="xs" />
      <SoftBadge variant="contained"
                 badgeContent="ویرایش"
                 onClick={() => updateModal("newMug", { state: true, mugId: item.id })}
                 color="secondary" size="xs" />
      <SoftBadge variant="contained"
                 badgeContent="حذف"
                 color="error" size="xs" />
    </section>,
  ));

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SoftBadge onClick={() => updateModal("newMug", { state: true })} style={{ cursor: "pointer" }}
                         variant="contained"
                         badgeContent="ماگ جدید"
                         color="success" size="sm" />
              <section style={{ display: "flex", alignItems: "center" }}>
                <MugSponsors sponsor={sponsor} handleChange={handleChange} />
                <SoftButton variant="gradient" onClick={onRefresh} circular size={"small"} color="info">
                  فیلتر
                </SoftButton>
                <SoftButton variant="text" onClick={handleDeleteFilter} circular size={"small"} color="error">
                  حذف فیلتر
                </SoftButton>
              </section>
              {sponsor && <SoftButton style={{ alignSelf: "flex-start" }} variant="gradient"
                          onClick={() => navigate("/mugs-qr-codes-preview", { state:data })} circular
                          size={"small"} color="warning">
                نمایش تمام کیو آرها
              </SoftButton>}
            </div>
            <TableRow>
              <TableCell className="tablecell" style={fixTableCellWidth("130px")}>اسپانسر</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("130px")}>امتیاز اضافی</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("150px")}> استفاده
                شده؟</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("130px")}>تاریخ ایجاد</TableCell>
              <TableCell className="tablecell" style={fixTableCellWidth("230px")}>عملیات ها</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ display: "block" }}>
            {rows.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modals.newMug.state && <NewMugModal modalInfo={modals.newMug} closeModal={closeNewMugModal} />}
      {modals.qrcode.state &&
        <QRInfoModal closeModal={closeQRCode} modalInfo={modals.qrcode} downloadFieldName={"id"} />}
    </>
  );
}
