/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default function NewMugFields({method, information, setInformation, handleUpdate, submit}){
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };

    return(
        <SoftBox py={3}>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    {method !== "update" && <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تعداد
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="count" value={information.count} onChange={handleChange}
                                   type="text" placeholder="تعداد"/>
                    </Grid>}
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                اسپانسر
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="sponsor" value={information.sponsor} onChange={handleChange}
                                   type="text" placeholder="اسپانسر"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                امتیاز اضافی
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="additional_points" value={information.additional_points} onChange={handleChange}
                                   type="text" placeholder="امتیاز اضافی"/>
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        آپدیت
                    </SoftButton>}
            </SoftBox>
        </SoftBox>
    )
}
