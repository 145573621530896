/* eslint-disable react/prop-types */
import mugWithData from "./mugWithData";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MugTable from "./MugTable";

const Mug = ({data,sponsor,onRefresh,handleDeleteFilter,handleChange})=>{
    return(
        <DashboardLayout>
            <DashboardNavbar/>
            <MugTable data={data} onRefresh={onRefresh} handleDeleteFilter={handleDeleteFilter} sponsor={sponsor} handleChange={handleChange}/>
        </DashboardLayout>
    )
}
export default mugWithData(Mug)
