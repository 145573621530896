/* eslint-disable react/prop-types */
import {deleteBusinessCategory} from "../../../api/routes";
import {toast} from "react-toastify";

/**
 *
 * @param {number} companyBusinessId
 * @param {function(value:boolean)} setLoading
 * @return void
 */
const handleDeleteBusinessCategory = (companyBusinessId,setLoading)=>{
    let answer = window.confirm("Are you sure about deleting category?");
    if (answer) {
        setLoading(true);
        deleteBusinessCategory(companyBusinessId, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                window.location.reload()
            }
        })
    } else {
    }
}
export default handleDeleteBusinessCategory
