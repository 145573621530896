import {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import NewCountryFields from "./NewCountryFields";
import {newCountry} from "../../api/routes";
import {toast} from "react-toastify";
import randomToken from "../../functions/randomToken";

export default function NewCountry() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();

    let schema = yup.object().shape({
        name: yup.string().required(),
        iso_code: yup.string().required(),
        phone_code: yup.string().required(),
        nationality: yup.string().required()
    });
    const submitNewCountry = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();

                    formdata.append("name", information?.name);
                    formdata.append("iso_code", information?.iso_code);
                    formdata.append("phone_code", information?.phone_code);
                    formdata.append("nationality", information?.nationality);

                    setLoading(true);
                    newCountry(formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(`/country?update=${randomToken()}`)
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <NewCountryFields information={information} setInformation={setInformation} submit={submitNewCountry}/>
        </DashboardLayout>
    )
}
