import {deleteMenuSection} from "../../../api/routes";
import {toast} from "react-toastify";
import randomToken from "../../../functions/randomToken";

/**
 *
 * @param {number} menuId
 * @param navigate
 * @param state
 * @param {function(value:boolean)} setLoading
 * @return void
 */
const handleDeleteMenu = (menuId,navigate,state,setLoading)=>{
    let answer = window.confirm("Are you sure about deleting menu?");
    if (answer) {
        setLoading(true);
        deleteMenuSection(menuId, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/menu?update=${randomToken()}`,{state});
            }
        })
    } else {
    }
}
export default handleDeleteMenu
