/* eslint-disable react/prop-types */
import QRCode from "react-qr-code";
import SoftButton from "./SoftButton";
import downloadQRCode from "../functions/downloadQRCode";
import * as React from "react";
import {useRef} from "react";

export default function GenerateQR({QRInfo,downloadFieldName="name"}){
    const qrCodeRef = useRef(null);

    return(
        <>
            <div ref={qrCodeRef} style={{display: "flex", justifyContent: "center"}}>
                <QRCode
                    size={256}
                    value={QRInfo?.uuid}
                    viewBox={`0 0 256 256`}
                    style={{padding: 4, backgroundColor: "white"}}
                />
            </div>
            <SoftButton onClick={() => downloadQRCode(qrCodeRef, QRInfo[downloadFieldName])} variant="gradient"
                        color="success">
                دانلود
            </SoftButton>
        </>
    )
}
