/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState} from "react";
import Loading from "../../components/Loading";
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import {useNavigate} from "react-router-dom";
import SoftBadge from "../../components/SoftBadge";
import {deleteProductTag} from "../../api/routes";
import {toast} from "react-toastify";

function createData(id, name, operation) {
    return {
        id,
        name,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={fixTableCellWidth("210px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("200px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ProductTagTable({data}) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleDeleteProductTag = (productTag) => {
        let answer = window.confirm("آیا از حذف اطمینان دارید؟");
        if (answer) {
            setLoading(true);
            deleteProductTag(productTag, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                    window.location.reload()
                }
            })
        }
    }

    const rows = data.map(item => createData(item.id, item.name, <section
            style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       onClick={() => navigate(`/new-product-tag?method=update&productTagId=${item.id}&productTagName=${item.name}`)}
                       badgeContent="آپدیت"
                       color="secondary" size="xs"/>
            <SoftBadge variant="contained"
                       badgeContent="حذف"
                       onClick={() => handleDeleteProductTag(item.id)}
                       color="error" size="xs"/>
        </section>
    ))

    return (
        <>
            <Loading loading={loading}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained"
                                   onClick={() => navigate("/new-product-tag")}
                                   badgeContent="تگ محصول جدید" color="success" size="xs"
                                   container
                                   style={{cursor: 'pointer', marginLeft: 5}}/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("210px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("200px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}
