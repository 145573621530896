import {useEffect, useMemo, useState} from "react";
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import routes from "routes";
import {setMiniSidenav, setOpenConfigurator, useSoftUIController} from "context";
import brand from "assets/images/EZAKKILogo.png";
import SignIn from "layouts/authentication/sign-in";
import Cookies from "universal-cookie";
import {getMe} from "api/routes";
import Home from "layouts/home";
import Loading from "components/Loading";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import partialRoutes from "./partialRoutes";
import {CacheProvider} from "@emotion/react";
import themeRtl from "./assets/theme/theme-rtl";

const cookies = new Cookies();

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, direction, layout, openConfigurator, sidenavColor} = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const [me, setMe] = useState()
    const {pathname} = useLocation();
    const [loading, setLoading] = useState(false)

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: "rtl",
            stylisPlugins: [rtlPlugin],
        });

        setRtlCache(cacheRtl);
    }, []);

    useEffect(() => {
        // call getMe api for finding user role
        if (cookies.get('is_access')) {
            setLoading(true)
            getMe((isOk, data) => {
                if (!isOk) {
                    setLoading(false)
                    if (data.status === 401) {
                        toast.error(data?.data?.message)
                        cookies.remove("is_access")
                        window.location.replace("/login")
                    } else return toast.error(data?.data?.message)
                } else {
                    setLoading(false);
                    cookies.set('role', data.roles[0], {path: '/'});
                    if (data?.agent?.id)
                        cookies.set('id', data?.agent?.id, {path: '/'});
                    return setMe(data);
                }
            })
        }
    }, [cookies.get('is_access')])

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {

            if (route.collapse && route.role.includes(me?.roles[0])) {
                return getRoutes(route.collapse);
            }

            if (route.route && route.role.includes(me?.roles[0])) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });
    /**
     * handle access to routes by user role
     * @param allRoutes
     * @returns routes
     */
    const privateRouteHandler = (allRoutes) => {
        return allRoutes.map((route) => {
            if (route.role.includes(me?.roles[0])) {
                return route
            } else return {}
        })
    }
    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{cursor: "pointer"}}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="default" color="inherit">
                settings
            </Icon>
        </SoftBox>
    );

    return direction === "rtl" && (
        <>
            <Loading loading={loading}/>
            <CacheProvider value={rtlCache}>
                <ThemeProvider theme={themeRtl}>
                    <CssBaseline/>
                    {layout === "dashboard" && (
                        <>
                            <Sidenav
                                color={sidenavColor}
                                brand={brand}
                                brandName="Dashboard"
                                routes={privateRouteHandler(routes)}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Configurator/>
                            {/* {configsButton} */}
                        </>
                    )}
                    {layout === "vr" && <Configurator/>}
                    <Routes>
                        <Route path="/login" element={<SignIn/>}/>
                        <Route path={'/'} element={<PrivateAfterLogin/>}>
                            {getRoutes(routes)}
                            {partialRoutes.map(route => <Route key={route.path} path={route.path}
                                                               element={route.element}/>)}
                        </Route>
                    </Routes>
                </ThemeProvider>
            </CacheProvider>
            <ToastContainer/>
        </>
    )
}
// handle user login status by cookies
const isLogin = () => !!cookies.get('is_access');
const PrivateAfterLogin = () => {
    return isLogin() ? <Outlet/> : <Navigate to={"/login"}/>
};
