/* eslint-disable react/prop-types */
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default function NewMenuFields({method, information, setInformation, submit,handleUpdate}){
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    return(
        <SoftBox py={3}>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام منو
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام منو"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        توضیحات
                                    </SoftTypography>
                        </SoftBox>
                        <SoftInput name="description" value={information.description} onChange={handleChange}
                                   type="text" placeholder="توضیحات"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                ترتیب
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="order" value={information.order} onChange={handleChange}
                                   type="text" placeholder="ترتیب"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                وضعیت نمایش
                            </SoftTypography>
                        </SoftBox>
                        <Select name="is_active" value={String(information.is_active)} onChange={handleChange}
                                fullWidth
                                placeholder='Is active'>
                            <MenuItem value={"1"}>فعال</MenuItem>
                            <MenuItem value={"0"}>غیرفعال</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        ویرایش
                    </SoftButton>}
            </SoftBox>
        </SoftBox>

    )
}
