/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import ellipsis from "../../functions/ellipsis";
import SoftBadge from "../../components/SoftBadge";
import useModal from "../../hooks/useModal";
import BusinessOfPersonModal from "./modal/BusinessOfPersonModal";
import TableHeadItems from "./TableHeadItems";

function createData(id, user, description, expertise, operation) {
    return {
        id,
        user,
        description,
        expertise,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}, maxHeight: "50px"}}>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row?.user?.first_name} {row?.user?.last_name}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row?.user?.username}@</TableCell>
                <TableCell className="tablecell" align="left" title={row.description}
                           style={fixTableCellWidth("230px")}>{ellipsis(row.description, 40)}</TableCell>
                <TableCell className="tablecell" align="left" title={row.expertise ? row.expertise.join(",") : null}
                           style={fixTableCellWidth("130px")}>{row.expertise ? row.expertise.join(",") : null}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("150px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function PersonTable({data, searchInfo, handleFilterChange, handleClickFilter}) {
    const [modals, updateModal, closeModal] = useModal({
        businessOfPerson: {state: false},
    })
    const closeBusinessOfPersonModal = () => closeModal('businessOfPerson');
    const rows = data.map(item => createData(item.id, item.user, item.description, item.expertise,
        <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("businessOfPerson", {state: true, personId: item.id})}
                       badgeContent="کسب و کار های وابسته"
                       color="info" size="xs"/>
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableHeadItems searchInfo={searchInfo} handleClickFilter={handleClickFilter}
                                        handleFilterChange={handleFilterChange}/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام شخص</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام کاربری
                                شخص</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("210px")}>توضیحات</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("130px")}>تخصص ها</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>عملیات</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.businessOfPerson.state &&
                <BusinessOfPersonModal modalInfo={modals.businessOfPerson} closeModal={closeBusinessOfPersonModal}/>}
        </>
    );
}
