import Loading from "../../components/Loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {getProductCategory} from "../../api/routes";
import {toast} from "react-toastify";
import ProductCategoryTable from "./ProductCategoryTable";

export default function ProductCategory(){
    const {state} = useLocation();
    const {forceUpdate} = state ?? [];
    const [loading, setLoading] = useState(false);
    const [productCategory, setProductCategory] = useState([]);

    useEffect(() => {
        setLoading(true);
        getProductCategory(forceUpdate ?? false, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                setProductCategory(data);
            }
        })

    }, [forceUpdate]);

    return(
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
            <ProductCategoryTable data={productCategory}/>
        </DashboardLayout>
    )
}
