/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../../../functions/fixTableCellWidth";
import SoftBadge from "../../../../components/SoftBadge";
import {deleteArea, newArea} from "../../../../api/routes";
import {toast} from "react-toastify";
import {useState} from "react";
import Loading from "../../../../components/Loading";
import Icon from "@mui/material/Icon";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftInput from "../../../../components/SoftInput";
import SoftButton from "../../../../components/SoftButton";

function createData(id, name,stats, operation) {
    return {
        id,
        name,
        stats,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={{...fixTableCellWidth("180px"), paddingRight: 30}}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row?.stats?.count}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function AreasTable({data, cityId}) {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [information, setInformation] = useState({});
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };

    const rows = data.map(item => createData(item.id, item.name,item.stats, <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       onClick={() => handleDeleteArea(item.id)}
                       badgeContent="حذف"
                       color="error" size="xs"/>
        </section>
    ))
    const handleDeleteArea = (areaId) => {
        let answer = window.confirm("Are you sure about deleting area?");
        if (answer) {
            setLoading(true);
            deleteArea(areaId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                }
            })
        } else {
        }
    }
    const submitNewArea = () => {
        let formdata = new FormData();

        formdata.append("name", information?.name);
        formdata.append("city_id", cityId);

        setLoading(true);
        newArea(formdata, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                setStep(0)
                setInformation({})
            }
        })
    }
    return (
        <>
            <Loading loading={loading}/>
            {step === 0 && <TableContainer component={Paper} style={{minWidth: "500px"}}>
                <Table>
                    <TableHead>
                        <SoftBadge variant="contained"
                                   badgeContent="منطقه جدید"
                                   style={{cursor: "pointer"}}
                                   onClick={() => setStep(1)}
                                   color="success" size="xs"/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>کاربران</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>عملیات</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            {step === 1 && <>
                <Icon onClick={() => setStep(0)}
                      style={{cursor: "pointer", position: "absolute", left: 25}}>arrow_back_ios_new</Icon>
                <SoftBox mb={1} ml={0.5} mt={2}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        نام منطقه
                    </SoftTypography>
                </SoftBox>
                <SoftInput name="name" value={information.name} onChange={handleChange}
                           type="text" placeholder="نام منطقه"/>
                <SoftButton onClick={submitNewArea} style={{marginTop: 30}} variant="gradient" color="info">
                    ثبت
                </SoftButton>
            </>}
        </>
    );
}
