/* eslint-disable react/prop-types */
import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

/**
 *
 * @param {boolean} loading
 * @return {JSX.Element}
 *
 */
export default function Loading({ loading }) {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
