/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SoftBadge from "../../components/SoftBadge";
import {useNavigate} from "react-router-dom";

export default function TableHeadItems({filterInfo,handleFilterChange}){
    const navigate = useNavigate();

    return(
        <Grid container gap={2} mb={2}>
            <Grid item xs={12} md={6} lg={2}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        نام
                    </SoftTypography>
                </SoftBox>
                <SoftInput value={filterInfo?.name} name="name" onChange={handleFilterChange}
                           type="text"/>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        وضعیت
                    </SoftTypography>
                </SoftBox>
                <Select name="status" value={filterInfo?.status} onChange={handleFilterChange}>
                    <MenuItem value="all">همه</MenuItem>
                    <MenuItem value="1">درخواست داده</MenuItem>
                    <MenuItem value="2">تایید نشده</MenuItem>
                    <MenuItem value="3">تایید شده</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12} md={6} lg={3} mt={5}>
                <SoftBadge onClick={() => navigate("/new-business")} variant="contained"
                           badgeContent="کسب و کار جدید" color="success" size="xs"
                           container
                           style={{cursor: 'pointer',marginLeft:5}}/>
                <SoftBadge onClick={() => navigate("/business-category")} variant="contained"
                           badgeContent="دسته بندی کسب و کار" color="info" size="xs"
                           container
                           style={{cursor: 'pointer'}}/>
            </Grid>
        </Grid>
    )
}
