import {useEffect, useState} from "react";
import {getFeature} from "../../api/routes";
import {toast} from "react-toastify";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Loading from "../../components/Loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import FeatureTable from "./FeatureTable";

export default function Feature() {
    const [loading, setLoading] = useState(false);
    const [feature, setFeature] = useState([]);

    useEffect(() => {
        setLoading(true);

        getFeature((isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setFeature(data)
                setLoading(false);
            }
        })
    }, []);

    return (
        <DashboardLayout>
            <Loading loading={loading}/>
            <DashboardNavbar/>
                <FeatureTable data={feature}/>
        </DashboardLayout>
    )
}
