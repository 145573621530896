/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import SoftBadge from "../../components/SoftBadge";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";

export default function TableHeadItems(){
    const {state} = useLocation();
    const {businessId,businessName} = state ?? [];

    const navigate = useNavigate()
    return(
        <Grid container gap={2} mb={2}>
            <Grid item xs={12} md={6} lg={1}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                    {businessName}
                </SoftTypography>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <SoftBadge onClick={() => navigate(`/new-menu?businessId=${businessId}`)} variant="contained"
                           badgeContent="منو جدید" color="success" size="xs"
                           container
                           style={{cursor: 'pointer'}}/>
            </Grid>
        </Grid>
    )
}
