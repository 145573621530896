/* eslint-disable react/prop-types */
import Loading from "../../components/Loading";
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {useEffect, useState} from "react";
import {FormControlLabel, Radio, RadioGroup, Stack, TextField} from "@mui/material";
import {getCustomer} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import * as React from "react";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import SoftButton from "../../components/SoftButton";
import {useSearchParams} from "react-router-dom";

export default function NewPenaltyFields({information, setInformation, submit}) {
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id")

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    useEffect(() => {
        // debounce for 1 second
        const getData = setTimeout(() => {
            const query = {
                phone_number: information.phone_number
            }
            setLoading(true);
            getCustomer(queryString(query), (isOk, data) => {
                setLoading(false);
                if (!isOk) {
                    return toast.error(data.message)
                } else {
                    setCustomer(data.data);
                }
            })
        }, 1000)

        return () => {
            clearTimeout(getData)
        }
    }, [information.phone_number]);

    const dateHandleChange = (value, name) => {
        setInformation({
            ...information,
            [name]: `${value.$d.getUTCFullYear()}-${String(value.$d.getUTCMonth() + 1).padStart(2, "0")}-${String(value.$d.getUTCDate()).padStart(2, "0")}`
        });
    };

    return (
        <SoftBox py={3}>
            <Loading loading={loading}/>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    {!customerId && <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                شماره تلفن مشتری
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="phone_number" style={{direction: "ltr"}} value={information.phone_number}
                                   onChange={handleChange}
                                   type="text" placeholder="98902xxxxxx"/>
                        <RadioGroup name="customer_id" style={{marginLeft: 15}}
                                    value={information?.customer_id} onChange={handleChange}>
                            {customer.length === 0 &&
                                <SoftTypography component="label" mt={1} variant="caption">مشتری یافت
                                    نشد</SoftTypography>}
                            {customer.map(item => <FormControlLabel key={item.id} value={item.id} control={<Radio/>}
                                                                    label={`${item?.user?.first_name} ${item?.user?.last_name}`}/>)}
                        </RadioGroup>
                    </Grid>}
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مقدار امتیاز
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="points_amount" value={information.points_amount} onChange={handleChange}
                                   type="text" placeholder="مقدار امتیاز"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                امتیاز جریمه
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="points_penalty" value={information.points_penalty} onChange={handleChange}
                                   type="text" placeholder="امتیاز جریمه"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                مقدار کیف پول
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="wallet_amount" value={information.wallet_amount} onChange={handleChange}
                                   type="text" placeholder="مقدار کیف پول"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                جریمه کیف پول
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="wallet_penalty" value={information.wallet_penalty} onChange={handleChange}
                                   type="text" placeholder="جریمه کیف پول"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                تاریخ اعمال
                            </SoftTypography>
                        </SoftBox>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={information.due_at}
                                    onChange={(newValue) => dateHandleChange(newValue, "due_at")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                        <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                            ثبت
                        </SoftButton>
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    )
}
