/**
 *
 * @param {string | number} width
 * @return {{wordBreak: string, minWidth: string, maxWidth: string}}
 */
const fixTableCellWidth = (width) => ({
    maxWidth: width ?? "0px",
    minWidth: width ?? "0px",
    wordBreak: "break-all"
})

export default fixTableCellWidth
