/* eslint-disable react/prop-types */
import * as React from "react";
import {Alert} from "@mui/material";
import ModalUi from "../../../components/ModalUI";
import separate from "../../../functions/separate";

export default function MoreInfoModal({modalInfo, closeModal}) {
    return (
        <ModalUi open={modalInfo.state} handleClose={closeModal} style={{minWidth: "600px"}}>
            <Alert severity="info" variant={"outlined"} sx={{mb: 1}}>شماره
                تماس: {`${modalInfo.info.contact_number_1} ${modalInfo.info.contact_number_2 ? `| ${modalInfo.info.contact_number_2}` : ""}`}</Alert>
            <Alert severity="info" sx={{mb: 1}}>آدرس: {modalInfo.info.address}</Alert>
            <Alert severity="info" variant={"outlined"} sx={{mb: 1}}>قیمت خرید کارت: {separate(modalInfo.info.discount_card_price)} تومان</Alert>
            <Alert severity="info" sx={{mb: 1}}>تاریخ
                ایجاد: {new Date(modalInfo.info.created_at).toLocaleString('fa-IR')}</Alert>
        </ModalUi>
    )
}
