import React from "react";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import Business from "./layouts/business";
import Customer from "./layouts/customer";
import ProductCategory from "./layouts/productCategory";
import Country from "./layouts/country";
import DiscountCard from "./layouts/discountCard";
import DiscountCardIssue from "./layouts/discountCardIssue";
import Map from "./layouts/map";
import Journey from "./layouts/journey";
import Qrcode from "./layouts/qrcode";
import Penalty from "./layouts/penalty";
import Banner from "./layouts/banner";
import RatingFactor from "./layouts/ratingFactor";
import ProductTag from "./layouts/productTag";
import Feature from "./layouts/feature";
import Person from "./layouts/person";
import Mug from "./layouts/mug";


const routes = [
    {
        type: "collapse",
        name: "کسب و کار",
        key: "business",
        role:["sudo"],
        route: "/business",
        icon: <Office size="12px" />,
        component: <Business />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "مشتری",
        key: "customer",
        role:["sudo"],
        route: "/customer",
        icon: <CustomerSupport size="12px" />,
        component: <Customer />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "دسته بندی محصولات",
        key: "product-category",
        role:["sudo"],
        route: "/product-category",
        icon: <Document size="12px" />,
        component: <ProductCategory />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "کشور",
        key: "country",
        role:["sudo"],
        route: "/country",
        icon: <Cube size="12px" />,
        component: <Country />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "کارت تخفیف",
        key: "discountCard",
        role:["sudo"],
        route: "/discountCard",
        icon: <CreditCard size="12px" />,
        component: <DiscountCard />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "کارت تخفیف کسب و کار",
        key: "discountCardIssue",
        role:["sudo"],
        route: "/discountCardIssue",
        icon: <Cube size="12px" />,
        component: <DiscountCardIssue />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "نقشه",
        key: "map",
        role:["sudo"],
        route: "/map",
        icon: <Cube size="12px" />,
        component: <Map />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "چالش",
        key: "journey",
        role:["sudo"],
        route: "/journey",
        icon: <SpaceShip size="12px" />,
        component: <Journey />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "QR",
        key: "qrcode",
        role:["sudo"],
        route: "/qrcode",
        icon: <Cube size="12px" />,
        component: <Qrcode />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "جریمه",
        key: "penalty",
        role:["sudo"],
        route: "/penalty",
        icon: <Cube size="12px" />,
        component: <Penalty />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "بنر",
        key: "banner",
        role:["sudo"],
        route: "/banner",
        icon: <Cube size="12px" />,
        component: <Banner />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "رای گیری",
        key: "ratingFactor",
        role:["sudo"],
        route: "/ratingFactor",
        icon: <Cube size="12px" />,
        component: <RatingFactor />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "تگ محصولات",
        key: "productTag",
        role:["sudo"],
        route: "/productTag",
        icon: <Cube size="12px" />,
        component: <ProductTag />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "ویژگی",
        key: "feature",
        role:["sudo"],
        route: "/feature",
        icon: <Cube size="12px" />,
        component: <Feature />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "اشخاص",
        key: "person",
        role:["sudo"],
        route: "/person",
        icon: <Cube size="12px" />,
        component: <Person />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "ماگ",
        key: "mug",
        role:["sudo"],
        route: "/mug",
        icon: <Cube size="12px" />,
        component: <Mug />,
        noCollapse: true,
    },
];

export default routes;
