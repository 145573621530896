/* eslint-disable react/prop-types */
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import ModalUi from "../../../components/ModalUI";
import Loading from "../../../components/Loading";
import {getBusinessOfPerson} from "../../../api/routes";
import queryString from "../../../functions/queryString";
import InfiniteScroll from "react-infinite-scroll-component";
import BusinessOfPersonTable from "./BusinessOfPersonTable";


export default function BusinessOfPersonModal({modalInfo, closeModal}) {
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [businessOfPerson, setBusinessOfPerson] = useState([]);

    useEffect(() => {
        if (modalInfo?.personId) {
            setLoading(true);
            const query = {
                cursor: cursor === null ? null : cursor,
            }
            getBusinessOfPerson(modalInfo?.personId, queryString(query), (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    if (page !== 0) {
                        setBusinessOfPerson((prev) => ([...prev, ...data.data]));
                    } else {
                        setBusinessOfPerson(data.data);
                    }
                    setLoading(false);
                    setCursor(data.meta.next_cursor === null ? '' : data.meta.next_cursor);
                    if (data.meta.next_cursor !== null)
                        return setHasMore(true);

                    else return setHasMore(false)
                }
            })
        }
    }, [page, modalInfo?.personId]);
    const fetchMoreData = () => {
        if (loading) return;
        if (hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }
    return (
        <ModalUi open={modalInfo.state} handleClose={closeModal}>
            <Loading loading={loading}/>
            {businessOfPerson.length > 0 ?
                <InfiniteScroll dataLength={businessOfPerson.length} next={fetchMoreData} hasMore={hasMore}>
                    <BusinessOfPersonTable data={businessOfPerson}/>
                </InfiniteScroll> : <p style={{fontSize: 16}}>اطلاعاتی ثبت نشده است!</p>}
        </ModalUi>
    )
}
