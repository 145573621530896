import * as htmlToImage from "html-to-image";

const downloadQRCode = (ref,name) => {
    htmlToImage
        .toPng(ref.current)
        .then(function (dataUrl) {
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `${name}.png`;
            link.click();
        })
        .catch(function (error) {
            alert(`Error generating QR code: ${error}`);
        });
};
export default downloadQRCode
