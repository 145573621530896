import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useEffect, useState} from "react";
import NewBusinessFields from "./NewBusinessFields";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getSingleBusiness, newBusiness, updateBusiness} from "../../api/routes";
import randomToken from "../../functions/randomToken";
import imageCompression from "browser-image-compression";

export default function NewBusiness() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const businessId = searchParams.get("businessId")

    let schema = yup.object().shape({
        position: yup.object().required(),
        contact_number_1: yup.string().required(),
        contact_number_2: yup.string(),
        city_id: yup.string().required(),
        name: yup.string().required(),
        address: yup.string().required(),
        user_email: yup.string().required().email(),
        user_password: yup.string().required().min(8).matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, "The password must have at least one uppercase letter and lower case letter"),
        user_password_confirmation: yup.string().required().min(8).matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, "The password must have at least one uppercase letter and lower case letter"),
        user_first_name: yup.string().required(),
        user_last_name: yup.string().required(),
        user_username: yup.string().required(),
        user_phone_number: yup.string().required(),
        avatar: yup.mixed(),
        documents: yup.mixed().required(),
        category_ids: yup.array(),
        area_id: yup.string().required(),
    });

    useEffect(() => {
        if (method === "update" && businessId) {
            setLoading(true);
            getSingleBusiness(businessId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {
                        name,
                        address,
                        city_id,
                        area_id,
                        contact_number_1,
                        contact_number_2,
                        lat,
                        long,
                        is_active,
                        user,
                        business_categories
                    } = data;
                    setInformation({
                        name,
                        address,
                        city_id,
                        area_id,
                        contact_number_1,
                        contact_number_2,
                        position: {
                            lat: lat,
                            lng: long
                        },
                        is_active: +is_active,
                        user_first_name: user?.first_name,
                        user_last_name: user?.last_name,
                        user_email: user?.email,
                        user_phone_number: user?.phone_number,
                        category_ids: business_categories.map(category => category.id),
                        user_username: user?.username
                    })

                }
            })
        }
    }, [businessId]);
    const submitNewBusiness = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    setLoading(true);
                    let formdata = new FormData();
                    const {category_ids, documents, position, avatar, ...restInformation} = information;
                    for (let item in restInformation) {
                        if (restInformation[item])
                            formdata.append(item, restInformation[item]);
                    }
                    formdata.append("country_id", "1");
                    if (position && position.lat) {
                        formdata.append("lat", position.lat);
                        formdata.append("long", position.lng);
                    }
                    if (documents.length > 0)
                        for (let i in documents)
                            if (documents[i] instanceof File) {
                                const compressedImage = await imageCompression(documents[i], {maxSizeMB: 2});
                                formdata.append(`documents[${i}]`, compressedImage);
                            }

                    if (avatar.length > 0 &&  avatar[0] instanceof File) {
                        const compressedImage = await imageCompression(avatar[0], {maxSizeMB: 2});
                        formdata.append(`avatar`, compressedImage);
                    }
                    for (let i in category_ids)
                        formdata.append(`category_ids[${i}]`, category_ids[i]);

                    newBusiness(formdata, (isOk, data) => {
                        setLoading(false);
                        if (!isOk) {
                            return toast.error(data.message)
                        } else {
                            toast.success("Your request has been submitted");
                            navigate(`/business?update=${randomToken()}`);
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    const handleBusinessUpdate = async () => {
        let formdata = new FormData();
        setLoading(true);
        const {category_ids, avatar, position, ...restInformation} = information;
        for (let item in restInformation) {
            if (restInformation[item])
                formdata.append(item, restInformation[item]);
        }
        formdata.append("country_id", "1");
        if (position && position.lat) {
            formdata.append("lat", position.lat);
            formdata.append("long", position.lng);
        }
        if (avatar && avatar.length > 0 &&  avatar[0] instanceof File) {
            const compressedImage = await imageCompression(avatar[0], {maxSizeMB: 2});
            formdata.append(`avatar`, compressedImage);
        }
        for (let i in category_ids)
            formdata.append(`category_ids[${i}]`, category_ids[i]);

        updateBusiness(formdata, businessId, (isOk, data) => {
            setLoading(false);
            if (!isOk) {
                return toast.error(data.message)
            } else {
                toast.success("Your request has been submitted");
                navigate(`/business?update=${randomToken()}`);
            }
        })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <NewBusinessFields method={method} information={information} handleUpdate={handleBusinessUpdate}
                               setInformation={setInformation} submit={submitNewBusiness}/>
        </DashboardLayout>
    )
}
