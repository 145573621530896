import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../components/Loading";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import NewFeatureFields from "./NewFeatureFields";
import {getSingleFeature, newFeature, updateFeature} from "../../api/routes";
import {toast} from "react-toastify";
import queryString from "../../functions/queryString";

export default function NewFeature() {
    const [loading, setLoading] = useState(false);
    const [information, setInformation] = useState({items: []});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const method = searchParams.get("method")
    const featureId = searchParams.get("featureId")

    let schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        is_multi: yup.string().required(),
        order: yup.string(),
        items: yup.array().required(),
    });
    useEffect(() => {
        if (method === "update" && featureId) {
            setLoading(true);
            getSingleFeature(featureId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    const {
                        name,
                        description,
                        is_multi,
                        items,
                        order,
                    } = data;
                    setInformation({
                        name,
                        description,
                        is_multi: +is_multi,
                        order: order,
                        items: items.map(item => ({id: Math.random(), name: item})),
                    })

                }
            })
        }
    }, [featureId]);

    const handleSubmitFeature = () => {
        schema.validate(information)
            .then(async (res) => {
                if (res) {
                    let formdata = new FormData();
                    const {items, ...restInformation} = information;
                    for (let item in restInformation) {
                        if (restInformation[item])
                            formdata.append(item, restInformation[item]);
                    }
                    for (let i in items)
                        formdata.append(`items[${i}]`, items[i].name ?? null);

                    setLoading(true);
                    newFeature(formdata, (isOk, data) => {
                        if (!isOk) {
                            setLoading(false);
                            return toast.error(data.message)
                        } else {
                            setLoading(false);
                            toast.success("Your request has been submitted");
                            navigate(`/feature`);
                        }
                    })
                }
            }).catch(err => {
            return toast.error(err.errors[0])
        })
    }
    const handleFeatureUpdate = () => {
        let data = {};
        const { items, ...restInformation } = information;
        for (let item in restInformation) {
            if (restInformation[item]) {
                data[item] = restInformation[item];
            }
        }
        setLoading(true)
        for (let i in items)
            data[`items[${i}]`] = items[i].name ?? null;

        updateFeature(featureId, queryString(data), (isOk, data) => {
            setLoading(false)
            if (!isOk) {
                return toast.error(data.message);
            } else {
                toast.success(`ویژگی آپدیت شد!`);
                navigate(`/feature`);
            }
        });
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading loading={loading}/>
            <NewFeatureFields method={method} handleUpdate={handleFeatureUpdate} information={information}
                              setInformation={setInformation} submit={handleSubmitFeature}/>
        </DashboardLayout>
    )
}
