import React from "react";
import {useState} from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Cookies from 'universal-cookie';

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";

import {loginRoute} from "api/routes";
import Loading from "components/Loading";
import {toast} from "react-toastify";

function SignIn() {
    const [loginInfo, setLoginInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const cookies = new Cookies();

    const onSubmit = () => {
        setLoading(true)
        const data = {
            phone_mail: loginInfo?.phone_mail,
            password: loginInfo?.password
        }
        loginRoute(data, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message);
            } else {
                setLoading(false);
                cookies.set('is_access', "aXNfYWNjZXNzOnRydWU", {path: '/', maxAge: 2592000});
                window.location.replace('/dashboard')
            }
        })

    }

    return (
        <CoverLayout
            title="Welcome back"
            description="Enter your email and password to sign in"
            image={curved9}
        >
            <Loading loading={loading}/>
            <SoftBox component="form" role="form">
                <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Email
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput value={loginInfo?.phone_mail}
                               onChange={(event) => setLoginInfo((prev) => ({...prev, phone_mail: event.target.value}))}
                               type="email" placeholder="example@example.com"/>
                </SoftBox>
                <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Password
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput value={loginInfo?.password}
                               onChange={(event) => setLoginInfo((prev) => ({...prev, password: event.target.value}))}
                               type="password" placeholder="Password"/>
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                    <SoftButton onClick={onSubmit} variant="gradient" color="info" fullWidth>
                        sign in
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </CoverLayout>
    );
}

export default SignIn;
