/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default function NewRatingFactorFields({method, information, setInformation, handleUpdate, submit}) {
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInformation({...information, [name]: value});
    };
    return (
        <SoftBox py={3}>
            <SoftBox component="form" role="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                نام
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="name" value={information.name} onChange={handleChange}
                                   type="text" placeholder="نام"/>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                توضیحات
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput name="description" value={information.description} onChange={handleChange}
                                   type="text" placeholder="توضیحات"/>
                    </Grid>
                </Grid>
                {method !== "update" ?
                    <SoftButton onClick={submit} style={{marginTop: 40}} variant="gradient" color="info">
                        ثبت
                    </SoftButton> :
                    <SoftButton onClick={handleUpdate} style={{marginTop: 40}} variant="gradient" color="info">
                        آپدیت
                    </SoftButton>}
            </SoftBox>
        </SoftBox>
    )
}
