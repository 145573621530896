/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import SoftBadge from "../../components/SoftBadge";
import {useNavigate} from "react-router-dom";
import {deleteBanner} from "../../api/routes";
import {toast} from "react-toastify";
import {useState} from "react";
import Loading from "../../components/Loading";
import ellipsis from "../../functions/ellipsis";

function createData(id, name, description, city, area, type, isActive, createdAt, operation) {
    return {
        id,
        description,
        name,
        city,
        area,
        type,
        isActive,
        createdAt,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={fixTableCellWidth("160px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.description}
                           style={fixTableCellWidth("200px")}>{ellipsis(row.description,40)}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("120px")}>{row.city}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("120px")}>{row.area}</TableCell>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("120px")}>{row.type}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("120px")}>{row.isActive ? "فعال" : "غیرفعال"}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("160px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function BannerTable({data}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const handleDeleteBanner = (bannerId) => {
        let answer = window.confirm("آیا از حذف اطمینان دارید؟");
        if (answer) {
            setLoading(true);
            deleteBanner(bannerId, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    toast.success("Your request has been submitted");
                }
            })
        }
    }
    const rows = data.map(item => createData(item.id, item.name, item.description, item?.city?.name, item?.area?.name,
        item?.type, item?.is_active, item?.user?.created_at,
        <section style={{display: "flex", cursor: 'pointer'}}>

            <SoftBadge variant="contained"
                       onClick={() => navigate(`/new-banner?bannerId=${item.id}&method=update`)}
                       badgeContent="ویرایش"
                       color="info" size="xs"/>
            <SoftBadge variant="contained"
                       badgeContent="حذف"
                       onClick={() => handleDeleteBanner(item.id)}
                       color="error" size="xs"/>
        </section>
    ))

    return (
        <>
            <TableContainer component={Paper}>
                <Loading loading={loading}/>
                <Table>
                    <TableHead>
                        <SoftBadge onClick={() => navigate("/new-banner")} style={{cursor: "pointer"}}
                                   variant="contained"
                                   badgeContent="بنر جدید"
                                   color="success" size="sm"/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>نام</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("200px")}>توضیحات</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>شهر</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>منطقه</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("110px")}>نوع</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("120px")}>وضعیت نمایش</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
