/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

const defaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: "auto",
    maxHeight: 800,
    borderRadius: 2,
    p: 4,
};

/**
 *
 * @param {JSX.Element} children
 * @param {function} handleClose
 * @param {boolean} open
 * @param style
 * @return {JSX.Element}
 *
 */
export default function ModalUi({ children, handleClose, open,style }) {
    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{...style,...defaultStyle}}>
                    {children}
                </Box>
            </Modal>
        </div>
    );
}
