/**
 * separate number
 * @param {string} Number
 * @param {number?} separateNumber
 * @param {string?} separator
 * @returns {string}
 */
export default function separate(Number, separateNumber = 3, separator = ",") {
    Number += '';
    Number = Number.replace(',', '');
    let x = Number.split('.');
    let y = x[0];
    let z = x.length > 1 ? '.' + x[1] : '';
    let rgx = new RegExp(`(\\d+)(\\d{${separateNumber}})`);
    while (rgx.test(y))
        y = y.replace(rgx, `$1${separator}$2`);
    return y + z;
}
