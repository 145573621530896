/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import {FormControlLabel, Radio, RadioGroup, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SoftInput from "../../components/SoftInput";
import {useEffect, useState} from "react";
import {getBusiness} from "../../api/routes";
import queryString from "../../functions/queryString";
import {toast} from "react-toastify";
import Loading from "../../components/Loading";

export default function TableHeadItems({filterInfo, handleFilterChange}) {
    const [business, setBusiness] = useState([]);
    const [loading, setLoading] = useState(false);
    const [businessName, setBusinessName] = useState();

    useEffect(() => {
        const getData = setTimeout(() => {
            if (businessName) {
                setLoading(true);
                const query = {
                    name: businessName ?? null,
                }
                getBusiness(queryString(query), false, (isOk, data) => {
                    if (!isOk) {
                        setLoading(false);
                        return toast.error(data.message)
                    } else {
                        setLoading(false);
                        setBusiness(data.data);
                    }
                })
            } else {
                setBusiness([])
            }
        }, 1000)

        return () => {
            clearTimeout(getData)
        }
    }, [businessName]);
    return (
        <Grid container gap={2} mb={2}>
            <Loading loading={loading}/>
            <Grid item xs={12} md={6} lg={2}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        نام کسب و کار
                    </SoftTypography>
                </SoftBox>
                <SoftInput value={businessName} name="businessName"
                           onChange={(event) => setBusinessName(event.target.value)}
                           type="text"/>
                <RadioGroup
                    name="business_id"
                    style={{marginLeft: 15}}
                    value={filterInfo?.business_id} onChange={handleFilterChange}
                >
                    {business.length > 0 && <FormControlLabel value={"all"} control={<Radio/>}
                                                              label={"All"}/>}
                    {business.map(item => <FormControlLabel key={item.id} value={item.id} control={<Radio/>}
                                                            label={item.name}/>)}
                </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Is Ready
                    </SoftTypography>
                </SoftBox>
                <Select name="is_ready" value={filterInfo?.is_ready} onChange={handleFilterChange}>
                    <MenuItem value="all">همه</MenuItem>
                    <MenuItem value="1">بله</MenuItem>
                    <MenuItem value="0">خیر</MenuItem>
                </Select>
            </Grid>
        </Grid>
    )
}
