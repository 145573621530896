/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import SoftBadge from "../../components/SoftBadge";
import ProductsModal from "./ProductsModal";
import useModal from "../../hooks/useModal";
import TableHeadItems from "./TableHeadItems";
import {useLocation, useNavigate} from "react-router-dom";
import handleDeleteMenu from "./functions/handleDeleteMenu";
import {useState} from "react";
import Loading from "../../components/Loading";

function createData(id, name, description, order, isActive, operation) {
    return {
        id,
        name,
        description,
        order,
        isActive,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={{...fixTableCellWidth("160px"), paddingLeft: 30}}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.description}
                           style={fixTableCellWidth("220px")}>{row.description}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row.order}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("100px")}>{row.isActive ? "فعال" : "غیرفعال"}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("200px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function MenuTable({data}) {
    const navigate = useNavigate()
    const {state} = useLocation();
    const {businessId} = state ?? [];
    const [loading, setLoading] = useState(false);
    const [modals, updateModal, closeModal] = useModal({
        products: {},
    })
    const closeProducts = () => closeModal('products');

    const rows = data.map(item => createData(item.id, item.name, item.description,
        item?.order, item.is_active, <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       onClick={() => updateModal("products", {state: true, data: item.products})}
                       badgeContent="محصولات"
                       color="info" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => navigate(`/new-menu?businessId=${businessId}&method=update&menuId=${item.id}`)}
                       badgeContent="آپدیت"
                       color="secondary" size="xs"/>
            <SoftBadge variant="contained"
                       onClick={() => handleDeleteMenu(item.id,navigate,state,setLoading)}
                       badgeContent="حذف"
                       color="error" size="xs"/>

        </section>
    ))

    return (
        <>
            <Loading loading={loading}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                            <TableHeadItems />
                            <TableRow>
                                <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام</TableCell>
                                <TableCell className="tablecell"
                                           style={fixTableCellWidth("210px")}>توضیحات</TableCell>
                                <TableCell className="tablecell" style={fixTableCellWidth("100px")}>ترتیب</TableCell>
                                <TableCell className="tablecell" style={fixTableCellWidth("120px")}>وضعیت نمایش</TableCell>
                                <TableCell className="tablecell"
                                           style={fixTableCellWidth("200px")}>عملیات ها</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.products.state && <ProductsModal products={modals.products} closeProducts={closeProducts}/>}
        </>
);
}
