/* eslint-disable react/prop-types */
import ModalUi from "../../../../components/ModalUI";
import {useEffect, useState} from "react";
import {getArea} from "../../../../api/routes";
import queryString from "../../../../functions/queryString";
import {toast} from "react-toastify";
import Loading from "../../../../components/Loading";
import AreasTable from "./AreasTable";

export default function AreasModal({areas, closeAreas}) {
    const [loading, setLoading] = useState(false);
    const [area, setArea] = useState([]);

    useEffect(() => {
        if (areas?.cityId !== undefined) {
            const query = {
                city_id: areas?.cityId,
                stats:1
            }
            setLoading(true);
            getArea(queryString(query), false, (isOk, data) => {
                if (!isOk) {
                    setLoading(false);
                    return toast.error(data.message)
                } else {
                    setLoading(false);
                    setArea(data)
                }
            })
        }
    }, [areas?.cityId]);

    return (
        <ModalUi open={areas.state} handleClose={closeAreas} style={{minWidth:500}}>
            <Loading loading={loading}/>
            <AreasTable data={area} cityId={areas?.cityId}/>
        </ModalUi>
    )
}
