/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState} from "react";
import Loading from "../../components/Loading";
import fixTableCellWidth from "../../functions/fixTableCellWidth";
import handleBusinessStatusView from "./functions/handleBusinessStatusView";
import {getBusinessDocuments, updateBusiness} from "../../api/routes";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import randomToken from "../../functions/randomToken";
import TableHeadItems from "./TableHeadItems";
import SoftBadge from "../../components/SoftBadge";
import ImageModal from "../../components/ImageModal";
import useModal from "../../hooks/useModal";
import MoreInfoModal from "./modal/MoreInfoModal";
import PersonOfBusinessModal from "./modal/personOfBusiness";

function createData(id, refid, name, location, isActive, status, operation) {
    return {
        id,
        refid,
        name,
        location,
        isActive,
        status,
        operation
    };
}

function Row(props) {
    const {row} = props;

    return (
        <React.Fragment>
            <TableRow
                sx={{'& > *': {borderBottom: 'unset'}, backgroundColor: row.isActive ? "" : "rgba(204,204,204,0.1)"}}>
                <TableCell className="tablecell" align="left" style={fixTableCellWidth("110px")}>{row.refid}</TableCell>
                <TableCell className="tablecell" align="left" title={row.name}
                           style={fixTableCellWidth("160px")}>{row.name}</TableCell>
                <TableCell className="tablecell" align="left" title={row.location}
                           style={fixTableCellWidth("160px")}>{row.location}</TableCell>
                <TableCell className="tablecell" align="left" title={row.status}
                           style={fixTableCellWidth("150px")}>{row.status}</TableCell>
                <TableCell className="tablecell" align="left"
                           style={fixTableCellWidth("400px")}>{row.operation}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function BusinessTable({data, filterInfo, handleFilterChange}) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [modals, updateModal, closeModal] = useModal({
        documents: {state: false},
        moreInfo: {state: false},
        personOfBusiness: {state: false},
    })
    const closeDocumentsModal = () => closeModal('documents');
    const closeMoreInfoModal = () => closeModal('moreInfo');
    const closePersonOfBusinessModal = () => closeModal('personOfBusiness');

    /**
     *
     * @param {number} status
     * @param {number} businessID
     */
    const handleUpdateBusinessStatus = (status, businessID) => {
        let formdata = new FormData();
        formdata.append("status", status);

        setLoading(true);
        updateBusiness(formdata, businessID, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                toast.success("Your request has been submitted");
                navigate(`/business?update=${randomToken()}`);
            }
        })
    }

    const handleShowBusinessDocuments = (businessID) => {
        setLoading(true);
        getBusinessDocuments(businessID, (isOk, data) => {
            if (!isOk) {
                setLoading(false);
                return toast.error(data.message)
            } else {
                setLoading(false);
                updateModal("documents", {
                    data: data.map(item => item.url),
                    state: true
                })
            }
        })
    }

    const rows = data.map(item => createData(item.id, item.refid, item.name, `${item?.country?.name} - ${item?.city?.name}`,
        item.is_active, item.status_name, <section style={{display: "flex", cursor: 'pointer'}}>
            <SoftBadge variant="contained"
                       badgeContent="اطلاعات بیشتر"
                       onClick={() => updateModal("moreInfo", {state: true, info: item})}
                       color="info" size="xs"/>
            <SoftBadge variant="contained" onClick={() => handleShowBusinessDocuments(item.id)}
                       badgeContent="مدارک"
                       color="warning" size="xs"/>
            {item.status === 3 && <SoftBadge variant="contained"
                                             onClick={() => navigate("/menu", {
                                                 state: {
                                                     businessId: item.id,
                                                     businessName: item.name
                                                 }
                                             })}
                                             badgeContent="منو"
                                             color="primary" size="xs"/>}
            {item.status === 3 && <SoftBadge variant="contained"
                                             onClick={() => updateModal("personOfBusiness", {state: true, businessId: item.id})}
                                             badgeContent="افراد کسب و کار"
                                             color="success" size="xs"/>}
            {handleBusinessStatusView(handleUpdateBusinessStatus, item.status, item.id)}
            <SoftBadge variant="contained"
                       onClick={() => navigate(`/new-business?method=update&businessId=${item.id}`)}
                       badgeContent="آپدیت"
                       color="secondary" size="xs"/>
        </section>
    ))

    return (
        <>
            <Loading loading={loading}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableHeadItems filterInfo={filterInfo} handleFilterChange={handleFilterChange}/>
                        <TableRow>
                            <TableCell className="tablecell" style={fixTableCellWidth("100px")}>کدپیگیری</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>نام کسب و
                                کار</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("160px")}>محل</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("150px")}>وضعیت</TableCell>
                            <TableCell className="tablecell" style={fixTableCellWidth("380px")}>عملیات ها</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{display: 'block'}}>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {modals.documents.state && <ImageModal image={modals.documents} closeImage={closeDocumentsModal}/>}
            {modals.moreInfo.state && <MoreInfoModal modalInfo={modals.moreInfo} closeModal={closeMoreInfoModal}/>}
            {modals.personOfBusiness.state && <PersonOfBusinessModal modalInfo={modals.personOfBusiness} closeModal={closePersonOfBusinessModal}/>}
        </>
    );
}
