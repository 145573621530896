/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import * as React from "react";
import SoftButton from "../../components/SoftButton";

export default function TableHeadItems({searchInfo,handleFilterChange,handleClickFilter}){

    return(
        <Grid container gap={2} mb={2}>
            <Grid item xs={12} md={6} lg={1}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        نام و نام خانوادگی
                    </SoftTypography>
                </SoftBox>
                <SoftInput value={searchInfo?.full_name} placeholder={"نام و نام خانوادگی"} name="full_name" onChange={handleFilterChange}
                           type="text"/>
            </Grid>
            <Grid item xs={12} md={6} lg={1}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        شماره موبایل
                    </SoftTypography>
                </SoftBox>
                <SoftInput value={searchInfo?.phone_number} placeholder={"98902xxxxxxx"} name="phone_number" onChange={handleFilterChange}
                           type="text"/>
            </Grid>
            <Grid item xs={12} md={6} lg={1}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        نام کاربری
                    </SoftTypography>
                </SoftBox>
                <SoftInput value={searchInfo?.username} name="username" placeholder={"نام کاربری"} onChange={handleFilterChange}
                           type="text"/>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <SoftButton variant="gradient" circular onClick={handleClickFilter} size={"small"} style={{marginTop:40}} color="info">
                    فیلتر
                </SoftButton>
            </Grid>
        </Grid>
    )
}
